<script setup lang="ts"></script>

<template>
    <div
        class="flex h-full w-full flex-col items-center justify-center gap-5"
        data-testid="RequestErrorPage"
    >
        <div class="mx-4 bg-white p-7 md:p-10">
            <div class="mx-auto mb-10 flex w-fit rounded-full bg-gray-50">
                <NuxtImg
                    src="/images/ErrorIllustration.webp"
                    alt=""
                    loading="lazy"
                    format="webp"
                    sizes="240px sm:320px"
                    class="my-0 sm:my-7"
                />
            </div>
            <slot name="errorMessage" />
        </div>
    </div>
</template>
