import { default as indexfMrPUmEmNnMeta } from "/src/packages/commerce/pages/index.vue?macro=true";
import { default as indexyxI0oiRDfMMeta } from "/src/packages/commerce/pages/kh/hoher-personalbedarf/index.vue?macro=true";
import { default as indexzQhFmNKhP5Meta } from "/src/packages/commerce/pages/kh/lange-besetzungsdauer/index.vue?macro=true";
import { default as index5kC8dub1gRMeta } from "/src/packages/commerce/pages/kh/mangel-an-bewerbungen/index.vue?macro=true";
import { default as indexcOsDXDeD8vMeta } from "/src/packages/commerce/pages/kh/starker-mitbewerb/index.vue?macro=true";
import { default as indexRpOmPzz00wMeta } from "/src/packages/manage-talents/pages/p/active-sourcing/info-new-version/index.vue?macro=true";
import { default as _91profileId_93fVrBgHFGO0Meta } from "/src/packages/manage-talents/pages/p/active-sourcing/matching/[jobId]/contact/[profileId].vue?macro=true";
import { default as indexhIfwCMbsc6Meta } from "/src/packages/manage-talents/pages/p/active-sourcing/matching/[jobId]/index.vue?macro=true";
import { default as indexPQOLr9L7kJMeta } from "/src/packages/manage-talents/pages/p/active-sourcing/matching/index.vue?macro=true";
import { default as _91profileId_93oo3mdwxK19Meta } from "/src/packages/manage-talents/pages/p/active-sourcing/messages/[profileId].vue?macro=true";
import { default as indexqsTrOxAgHPMeta } from "/src/packages/manage-talents/pages/p/active-sourcing/messages/index.vue?macro=true";
import { default as _91profileId_93EyDT4aRaOeMeta } from "/src/packages/manage-talents/pages/p/active-sourcing/projects/[projectId]/contact/[profileId].vue?macro=true";
import { default as index9PJ5fiytowMeta } from "/src/packages/manage-talents/pages/p/active-sourcing/projects/[projectId]/index.vue?macro=true";
import { default as indexJlY6sWvka1Meta } from "/src/packages/manage-talents/pages/p/active-sourcing/projects/index.vue?macro=true";
import { default as indexdKsjhAlBgUMeta } from "/src/packages/manage-talents/pages/p/active-sourcing/releases/index.vue?macro=true";
import { default as indexPqRN0zQamnMeta } from "/src/packages/manage-talents/pages/p/active-sourcing/saved-search/index.vue?macro=true";
import { default as _91profileId_93dl3IrIvEz1Meta } from "/src/packages/manage-talents/pages/p/active-sourcing/search/candidate/[profileId].vue?macro=true";
import { default as _91profileId_93MbfQx3TTNWMeta } from "/src/packages/manage-talents/pages/p/active-sourcing/search/contact/[profileId].vue?macro=true";
import { default as indexAR57NjT5WrMeta } from "/src/packages/manage-talents/pages/p/active-sourcing/search/index.vue?macro=true";
import { default as active_45sourcing7OLKiAhdFhMeta } from "/src/packages/manage-talents/pages/p/active-sourcing.vue?macro=true";
import { default as kitchensinkJXBfwHMJ9GMeta } from "/src/packages/base/pages/p/base/kitchensink.vue?macro=true";
import { default as debugmBKVAi18OOMeta } from "/src/pages/p/debug.vue?macro=true";
import { default as index2bLt1VE2aPMeta } from "/src/packages/employer-branding/pages/p/employer-branding/dashboard/index.vue?macro=true";
import { default as indexZLR0vaOUPMMeta } from "/src/pages/p/index.vue?macro=true";
import { default as indexoBUpKj1P1vMeta } from "/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/index.vue?macro=true";
import { default as applicationqfBF31XmzkMeta } from "/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/jobs/[id]/application.vue?macro=true";
import { default as content9WXWW55uTuMeta } from "/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/jobs/[id]/content.vue?macro=true";
import { default as datayQKwtNOo1FMeta } from "/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/jobs/[id]/data.vue?macro=true";
import { default as designXeiXbGRtFOMeta } from "/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/jobs/[id]/design.vue?macro=true";
import { default as publishPB2KfEZtLtMeta } from "/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/jobs/[id]/publish.vue?macro=true";
import { default as summary42Wd0kKN2kMeta } from "/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/jobs/[id]/summary.vue?macro=true";
import { default as indexUNEBTTK2BnMeta } from "/src/packages/manage-job-ads/pages/p/internal/index.vue?macro=true";
import { default as loginj7QtVC2JgQMeta } from "/src/packages/manage-job-ads/pages/p/internal/login.vue?macro=true";
import { default as index1oPFKE9nI1Meta } from "/src/packages/manage-job-ads/pages/p/internal/qa/[id]/index.vue?macro=true";
import { default as reportb7lmFIqP2NMeta } from "/src/packages/manage-job-ads/pages/p/internal/qa/[id]/report.vue?macro=true";
import { default as indexWm53IRRnukMeta } from "/src/packages/manage-job-ads/pages/p/internal/qa/index.vue?macro=true";
import { default as applicationgMCwXMpYa6Meta } from "/src/packages/manage-job-ads/pages/p/jobs/[id]/application.vue?macro=true";
import { default as contentPU8gHutpq1Meta } from "/src/packages/manage-job-ads/pages/p/jobs/[id]/content.vue?macro=true";
import { default as datahqTxdtlcJLMeta } from "/src/packages/manage-job-ads/pages/p/jobs/[id]/data.vue?macro=true";
import { default as designC7DDEunE78Meta } from "/src/packages/manage-job-ads/pages/p/jobs/[id]/design.vue?macro=true";
import { default as publishsOIdBGknsOMeta } from "/src/packages/manage-job-ads/pages/p/jobs/[id]/publish.vue?macro=true";
import { default as summaryLIWG0UkFzIMeta } from "/src/packages/manage-job-ads/pages/p/jobs/[id]/summary.vue?macro=true";
import { default as createC0tzDknFOVMeta } from "/src/packages/manage-job-ads/pages/p/jobs/create.vue?macro=true";
import { default as indexGbIgta4ZrDMeta } from "/src/packages/manage-job-ads/pages/p/jobs/index.vue?macro=true";
import { default as checkboxRE1ixhRQlSMeta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/checkbox.vue?macro=true";
import { default as comboboxjML1MNnV6lMeta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/combobox.vue?macro=true";
import { default as date_45pickerTTWC8WEwriMeta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/date-picker.vue?macro=true";
import { default as file_45uploadPammO7IgKIMeta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/file-upload.vue?macro=true";
import { default as groupuWAisqKDeeMeta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/group.vue?macro=true";
import { default as indexXt6T92YthaMeta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/index.vue?macro=true";
import { default as infobox9xUy6x6Hg7Meta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/infobox.vue?macro=true";
import { default as linkgWstdA2Wu9Meta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/link.vue?macro=true";
import { default as notificationinStvDRa3GMeta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/notification.vue?macro=true";
import { default as pillsjQnxpuE69Meta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/pill.vue?macro=true";
import { default as sectionLMQLqSmNANMeta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/section.vue?macro=true";
import { default as selectjAxg7kKqGHMeta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/select.vue?macro=true";
import { default as stepperwB4XSGS1iZMeta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/stepper.vue?macro=true";
import { default as tableleGRHtvH5BMeta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/table.vue?macro=true";
import { default as tabsndwBUd3GYKMeta } from "/src/packages/manage-job-ads/pages/p/jobs/styleguide/tabs.vue?macro=true";
import { default as _91id_93TWSRcnAikPMeta } from "/src/packages/commerce/pages/produkte/arbeitgeberprofil/[id].vue?macro=true";
import { default as indexM31GbtQmXoMeta } from "/src/packages/commerce/pages/produkte/arbeitgeberprofil/index.vue?macro=true";
import { default as _91id_93NhJgvXYREyMeta } from "/src/packages/commerce/pages/produkte/stelleninserate/[id].vue?macro=true";
import { default as indexREBqFjvjkTMeta } from "/src/packages/commerce/pages/produkte/stelleninserate/index.vue?macro=true";
import { default as _91id_93WT9JbdJMv4Meta } from "/src/packages/commerce/pages/produkte/talentsuche/[id].vue?macro=true";
import { default as index1S1WiPGi2gMeta } from "/src/packages/commerce/pages/produkte/talentsuche/index.vue?macro=true";
export default [
  {
    name: indexfMrPUmEmNnMeta?.name ?? "index",
    path: indexfMrPUmEmNnMeta?.path ?? "/",
    meta: indexfMrPUmEmNnMeta || {},
    alias: indexfMrPUmEmNnMeta?.alias || [],
    redirect: indexfMrPUmEmNnMeta?.redirect,
    component: () => import("/src/packages/commerce/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexyxI0oiRDfMMeta?.name ?? "kh-hoher-personalbedarf",
    path: indexyxI0oiRDfMMeta?.path ?? "/kh/hoher-personalbedarf",
    meta: indexyxI0oiRDfMMeta || {},
    alias: indexyxI0oiRDfMMeta?.alias || [],
    redirect: indexyxI0oiRDfMMeta?.redirect,
    component: () => import("/src/packages/commerce/pages/kh/hoher-personalbedarf/index.vue").then(m => m.default || m)
  },
  {
    name: indexzQhFmNKhP5Meta?.name ?? "kh-lange-besetzungsdauer",
    path: indexzQhFmNKhP5Meta?.path ?? "/kh/lange-besetzungsdauer",
    meta: indexzQhFmNKhP5Meta || {},
    alias: indexzQhFmNKhP5Meta?.alias || [],
    redirect: indexzQhFmNKhP5Meta?.redirect,
    component: () => import("/src/packages/commerce/pages/kh/lange-besetzungsdauer/index.vue").then(m => m.default || m)
  },
  {
    name: index5kC8dub1gRMeta?.name ?? "kh-mangel-an-bewerbungen",
    path: index5kC8dub1gRMeta?.path ?? "/kh/mangel-an-bewerbungen",
    meta: index5kC8dub1gRMeta || {},
    alias: index5kC8dub1gRMeta?.alias || [],
    redirect: index5kC8dub1gRMeta?.redirect,
    component: () => import("/src/packages/commerce/pages/kh/mangel-an-bewerbungen/index.vue").then(m => m.default || m)
  },
  {
    name: indexcOsDXDeD8vMeta?.name ?? "kh-starker-mitbewerb",
    path: indexcOsDXDeD8vMeta?.path ?? "/kh/starker-mitbewerb",
    meta: indexcOsDXDeD8vMeta || {},
    alias: indexcOsDXDeD8vMeta?.alias || [],
    redirect: indexcOsDXDeD8vMeta?.redirect,
    component: () => import("/src/packages/commerce/pages/kh/starker-mitbewerb/index.vue").then(m => m.default || m)
  },
  {
    name: active_45sourcing7OLKiAhdFhMeta?.name ?? "p-active-sourcing",
    path: active_45sourcing7OLKiAhdFhMeta?.path ?? "/p/active-sourcing",
    meta: active_45sourcing7OLKiAhdFhMeta || {},
    alias: active_45sourcing7OLKiAhdFhMeta?.alias || [],
    redirect: active_45sourcing7OLKiAhdFhMeta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing.vue").then(m => m.default || m),
    children: [
  {
    name: indexRpOmPzz00wMeta?.name ?? "p-active-sourcing-info-new-version",
    path: indexRpOmPzz00wMeta?.path ?? "info-new-version",
    meta: indexRpOmPzz00wMeta || {},
    alias: indexRpOmPzz00wMeta?.alias || [],
    redirect: indexRpOmPzz00wMeta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/info-new-version/index.vue").then(m => m.default || m)
  },
  {
    name: _91profileId_93fVrBgHFGO0Meta?.name ?? "p-active-sourcing-matching-jobId-contact-profileId",
    path: _91profileId_93fVrBgHFGO0Meta?.path ?? "matching/:jobId()/contact/:profileId()",
    meta: _91profileId_93fVrBgHFGO0Meta || {},
    alias: _91profileId_93fVrBgHFGO0Meta?.alias || [],
    redirect: _91profileId_93fVrBgHFGO0Meta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/matching/[jobId]/contact/[profileId].vue").then(m => m.default || m)
  },
  {
    name: indexhIfwCMbsc6Meta?.name ?? "p-active-sourcing-matching-jobId",
    path: indexhIfwCMbsc6Meta?.path ?? "matching/:jobId()",
    meta: indexhIfwCMbsc6Meta || {},
    alias: indexhIfwCMbsc6Meta?.alias || [],
    redirect: indexhIfwCMbsc6Meta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/matching/[jobId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPQOLr9L7kJMeta?.name ?? "p-active-sourcing-matching",
    path: indexPQOLr9L7kJMeta?.path ?? "matching",
    meta: indexPQOLr9L7kJMeta || {},
    alias: indexPQOLr9L7kJMeta?.alias || [],
    redirect: indexPQOLr9L7kJMeta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/matching/index.vue").then(m => m.default || m)
  },
  {
    name: _91profileId_93oo3mdwxK19Meta?.name ?? "p-active-sourcing-messages-profileId",
    path: _91profileId_93oo3mdwxK19Meta?.path ?? "messages/:profileId()",
    meta: _91profileId_93oo3mdwxK19Meta || {},
    alias: _91profileId_93oo3mdwxK19Meta?.alias || [],
    redirect: _91profileId_93oo3mdwxK19Meta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/messages/[profileId].vue").then(m => m.default || m)
  },
  {
    name: indexqsTrOxAgHPMeta?.name ?? "p-active-sourcing-messages",
    path: indexqsTrOxAgHPMeta?.path ?? "messages",
    meta: indexqsTrOxAgHPMeta || {},
    alias: indexqsTrOxAgHPMeta?.alias || [],
    redirect: indexqsTrOxAgHPMeta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/messages/index.vue").then(m => m.default || m)
  },
  {
    name: _91profileId_93EyDT4aRaOeMeta?.name ?? "p-active-sourcing-projects-projectId-contact-profileId",
    path: _91profileId_93EyDT4aRaOeMeta?.path ?? "projects/:projectId()/contact/:profileId()",
    meta: _91profileId_93EyDT4aRaOeMeta || {},
    alias: _91profileId_93EyDT4aRaOeMeta?.alias || [],
    redirect: _91profileId_93EyDT4aRaOeMeta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/projects/[projectId]/contact/[profileId].vue").then(m => m.default || m)
  },
  {
    name: index9PJ5fiytowMeta?.name ?? "p-active-sourcing-projects-projectId",
    path: index9PJ5fiytowMeta?.path ?? "projects/:projectId()",
    meta: index9PJ5fiytowMeta || {},
    alias: index9PJ5fiytowMeta?.alias || [],
    redirect: index9PJ5fiytowMeta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/projects/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJlY6sWvka1Meta?.name ?? "p-active-sourcing-projects",
    path: indexJlY6sWvka1Meta?.path ?? "projects",
    meta: indexJlY6sWvka1Meta || {},
    alias: indexJlY6sWvka1Meta?.alias || [],
    redirect: indexJlY6sWvka1Meta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/projects/index.vue").then(m => m.default || m)
  },
  {
    name: indexdKsjhAlBgUMeta?.name ?? "p-active-sourcing-releases",
    path: indexdKsjhAlBgUMeta?.path ?? "releases",
    meta: indexdKsjhAlBgUMeta || {},
    alias: indexdKsjhAlBgUMeta?.alias || [],
    redirect: indexdKsjhAlBgUMeta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/releases/index.vue").then(m => m.default || m)
  },
  {
    name: indexPqRN0zQamnMeta?.name ?? "p-active-sourcing-saved-search",
    path: indexPqRN0zQamnMeta?.path ?? "saved-search",
    meta: indexPqRN0zQamnMeta || {},
    alias: indexPqRN0zQamnMeta?.alias || [],
    redirect: indexPqRN0zQamnMeta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/saved-search/index.vue").then(m => m.default || m)
  },
  {
    name: _91profileId_93dl3IrIvEz1Meta?.name ?? "p-active-sourcing-search-candidate-profileId",
    path: _91profileId_93dl3IrIvEz1Meta?.path ?? "search/candidate/:profileId()",
    meta: _91profileId_93dl3IrIvEz1Meta || {},
    alias: _91profileId_93dl3IrIvEz1Meta?.alias || [],
    redirect: _91profileId_93dl3IrIvEz1Meta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/search/candidate/[profileId].vue").then(m => m.default || m)
  },
  {
    name: _91profileId_93MbfQx3TTNWMeta?.name ?? "p-active-sourcing-search-contact-profileId",
    path: _91profileId_93MbfQx3TTNWMeta?.path ?? "search/contact/:profileId()",
    meta: _91profileId_93MbfQx3TTNWMeta || {},
    alias: _91profileId_93MbfQx3TTNWMeta?.alias || [],
    redirect: _91profileId_93MbfQx3TTNWMeta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/search/contact/[profileId].vue").then(m => m.default || m)
  },
  {
    name: indexAR57NjT5WrMeta?.name ?? "p-active-sourcing-search",
    path: indexAR57NjT5WrMeta?.path ?? "search",
    meta: indexAR57NjT5WrMeta || {},
    alias: indexAR57NjT5WrMeta?.alias || [],
    redirect: indexAR57NjT5WrMeta?.redirect,
    component: () => import("/src/packages/manage-talents/pages/p/active-sourcing/search/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: kitchensinkJXBfwHMJ9GMeta?.name ?? "p-base-kitchensink",
    path: kitchensinkJXBfwHMJ9GMeta?.path ?? "/p/base/kitchensink",
    meta: kitchensinkJXBfwHMJ9GMeta || {},
    alias: kitchensinkJXBfwHMJ9GMeta?.alias || [],
    redirect: kitchensinkJXBfwHMJ9GMeta?.redirect,
    component: () => import("/src/packages/base/pages/p/base/kitchensink.vue").then(m => m.default || m)
  },
  {
    name: debugmBKVAi18OOMeta?.name ?? "p-debug",
    path: debugmBKVAi18OOMeta?.path ?? "/p/debug",
    meta: debugmBKVAi18OOMeta || {},
    alias: debugmBKVAi18OOMeta?.alias || [],
    redirect: debugmBKVAi18OOMeta?.redirect,
    component: () => import("/src/pages/p/debug.vue").then(m => m.default || m)
  },
  {
    name: index2bLt1VE2aPMeta?.name ?? "p-employer-branding-dashboard",
    path: index2bLt1VE2aPMeta?.path ?? "/p/employer-branding/dashboard",
    meta: index2bLt1VE2aPMeta || {},
    alias: index2bLt1VE2aPMeta?.alias || [],
    redirect: index2bLt1VE2aPMeta?.redirect,
    component: () => import("/src/packages/employer-branding/pages/p/employer-branding/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexZLR0vaOUPMMeta?.name ?? "p",
    path: indexZLR0vaOUPMMeta?.path ?? "/p",
    meta: indexZLR0vaOUPMMeta || {},
    alias: indexZLR0vaOUPMMeta?.alias || [],
    redirect: indexZLR0vaOUPMMeta?.redirect,
    component: () => import("/src/pages/p/index.vue").then(m => m.default || m)
  },
  {
    name: indexoBUpKj1P1vMeta?.name ?? "p-internal-companies-companyId",
    path: indexoBUpKj1P1vMeta?.path ?? "/p/internal/companies/:companyId()",
    meta: indexoBUpKj1P1vMeta || {},
    alias: indexoBUpKj1P1vMeta?.alias || [],
    redirect: indexoBUpKj1P1vMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/index.vue").then(m => m.default || m)
  },
  {
    name: applicationqfBF31XmzkMeta?.name ?? "p-internal-companies-companyId-jobs-id-application",
    path: applicationqfBF31XmzkMeta?.path ?? "/p/internal/companies/:companyId()/jobs/:id()/application",
    meta: applicationqfBF31XmzkMeta || {},
    alias: applicationqfBF31XmzkMeta?.alias || [],
    redirect: applicationqfBF31XmzkMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/jobs/[id]/application.vue").then(m => m.default || m)
  },
  {
    name: content9WXWW55uTuMeta?.name ?? "p-internal-companies-companyId-jobs-id-content",
    path: content9WXWW55uTuMeta?.path ?? "/p/internal/companies/:companyId()/jobs/:id()/content",
    meta: content9WXWW55uTuMeta || {},
    alias: content9WXWW55uTuMeta?.alias || [],
    redirect: content9WXWW55uTuMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/jobs/[id]/content.vue").then(m => m.default || m)
  },
  {
    name: datayQKwtNOo1FMeta?.name ?? "p-internal-companies-companyId-jobs-id-data",
    path: datayQKwtNOo1FMeta?.path ?? "/p/internal/companies/:companyId()/jobs/:id()/data",
    meta: datayQKwtNOo1FMeta || {},
    alias: datayQKwtNOo1FMeta?.alias || [],
    redirect: datayQKwtNOo1FMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/jobs/[id]/data.vue").then(m => m.default || m)
  },
  {
    name: designXeiXbGRtFOMeta?.name ?? "p-internal-companies-companyId-jobs-id-design",
    path: designXeiXbGRtFOMeta?.path ?? "/p/internal/companies/:companyId()/jobs/:id()/design",
    meta: designXeiXbGRtFOMeta || {},
    alias: designXeiXbGRtFOMeta?.alias || [],
    redirect: designXeiXbGRtFOMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/jobs/[id]/design.vue").then(m => m.default || m)
  },
  {
    name: publishPB2KfEZtLtMeta?.name ?? "p-internal-companies-companyId-jobs-id-publish",
    path: publishPB2KfEZtLtMeta?.path ?? "/p/internal/companies/:companyId()/jobs/:id()/publish",
    meta: publishPB2KfEZtLtMeta || {},
    alias: publishPB2KfEZtLtMeta?.alias || [],
    redirect: publishPB2KfEZtLtMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/jobs/[id]/publish.vue").then(m => m.default || m)
  },
  {
    name: summary42Wd0kKN2kMeta?.name ?? "p-internal-companies-companyId-jobs-id-summary",
    path: summary42Wd0kKN2kMeta?.path ?? "/p/internal/companies/:companyId()/jobs/:id()/summary",
    meta: summary42Wd0kKN2kMeta || {},
    alias: summary42Wd0kKN2kMeta?.alias || [],
    redirect: summary42Wd0kKN2kMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/internal/companies/[companyId]/jobs/[id]/summary.vue").then(m => m.default || m)
  },
  {
    name: indexUNEBTTK2BnMeta?.name ?? "p-internal",
    path: indexUNEBTTK2BnMeta?.path ?? "/p/internal",
    meta: indexUNEBTTK2BnMeta || {},
    alias: indexUNEBTTK2BnMeta?.alias || [],
    redirect: indexUNEBTTK2BnMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/internal/index.vue").then(m => m.default || m)
  },
  {
    name: loginj7QtVC2JgQMeta?.name ?? "p-internal-login",
    path: loginj7QtVC2JgQMeta?.path ?? "/p/internal/login",
    meta: loginj7QtVC2JgQMeta || {},
    alias: loginj7QtVC2JgQMeta?.alias || [],
    redirect: loginj7QtVC2JgQMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/internal/login.vue").then(m => m.default || m)
  },
  {
    name: index1oPFKE9nI1Meta?.name ?? "p-internal-qa-id",
    path: index1oPFKE9nI1Meta?.path ?? "/p/internal/qa/:id()",
    meta: index1oPFKE9nI1Meta || {},
    alias: index1oPFKE9nI1Meta?.alias || [],
    redirect: index1oPFKE9nI1Meta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/internal/qa/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: reportb7lmFIqP2NMeta?.name ?? "p-internal-qa-id-report",
    path: reportb7lmFIqP2NMeta?.path ?? "/p/internal/qa/:id()/report",
    meta: reportb7lmFIqP2NMeta || {},
    alias: reportb7lmFIqP2NMeta?.alias || [],
    redirect: reportb7lmFIqP2NMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/internal/qa/[id]/report.vue").then(m => m.default || m)
  },
  {
    name: indexWm53IRRnukMeta?.name ?? "p-internal-qa",
    path: indexWm53IRRnukMeta?.path ?? "/p/internal/qa",
    meta: indexWm53IRRnukMeta || {},
    alias: indexWm53IRRnukMeta?.alias || [],
    redirect: indexWm53IRRnukMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/internal/qa/index.vue").then(m => m.default || m)
  },
  {
    name: applicationgMCwXMpYa6Meta?.name ?? "p-jobs-id-application",
    path: applicationgMCwXMpYa6Meta?.path ?? "/p/jobs/:id()/application",
    meta: applicationgMCwXMpYa6Meta || {},
    alias: applicationgMCwXMpYa6Meta?.alias || [],
    redirect: applicationgMCwXMpYa6Meta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/[id]/application.vue").then(m => m.default || m)
  },
  {
    name: contentPU8gHutpq1Meta?.name ?? "p-jobs-id-content",
    path: contentPU8gHutpq1Meta?.path ?? "/p/jobs/:id()/content",
    meta: contentPU8gHutpq1Meta || {},
    alias: contentPU8gHutpq1Meta?.alias || [],
    redirect: contentPU8gHutpq1Meta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/[id]/content.vue").then(m => m.default || m)
  },
  {
    name: datahqTxdtlcJLMeta?.name ?? "p-jobs-id-data",
    path: datahqTxdtlcJLMeta?.path ?? "/p/jobs/:id()/data",
    meta: datahqTxdtlcJLMeta || {},
    alias: datahqTxdtlcJLMeta?.alias || [],
    redirect: datahqTxdtlcJLMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/[id]/data.vue").then(m => m.default || m)
  },
  {
    name: designC7DDEunE78Meta?.name ?? "p-jobs-id-design",
    path: designC7DDEunE78Meta?.path ?? "/p/jobs/:id()/design",
    meta: designC7DDEunE78Meta || {},
    alias: designC7DDEunE78Meta?.alias || [],
    redirect: designC7DDEunE78Meta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/[id]/design.vue").then(m => m.default || m)
  },
  {
    name: publishsOIdBGknsOMeta?.name ?? "p-jobs-id-publish",
    path: publishsOIdBGknsOMeta?.path ?? "/p/jobs/:id()/publish",
    meta: publishsOIdBGknsOMeta || {},
    alias: publishsOIdBGknsOMeta?.alias || [],
    redirect: publishsOIdBGknsOMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/[id]/publish.vue").then(m => m.default || m)
  },
  {
    name: summaryLIWG0UkFzIMeta?.name ?? "p-jobs-id-summary",
    path: summaryLIWG0UkFzIMeta?.path ?? "/p/jobs/:id()/summary",
    meta: summaryLIWG0UkFzIMeta || {},
    alias: summaryLIWG0UkFzIMeta?.alias || [],
    redirect: summaryLIWG0UkFzIMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/[id]/summary.vue").then(m => m.default || m)
  },
  {
    name: createC0tzDknFOVMeta?.name ?? "p-jobs-create",
    path: createC0tzDknFOVMeta?.path ?? "/p/jobs/create",
    meta: createC0tzDknFOVMeta || {},
    alias: createC0tzDknFOVMeta?.alias || [],
    redirect: createC0tzDknFOVMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: indexGbIgta4ZrDMeta?.name ?? "p-jobs",
    path: indexGbIgta4ZrDMeta?.path ?? "/p/jobs",
    meta: indexGbIgta4ZrDMeta || {},
    alias: indexGbIgta4ZrDMeta?.alias || [],
    redirect: indexGbIgta4ZrDMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: checkboxRE1ixhRQlSMeta?.name ?? "p-jobs-styleguide-checkbox",
    path: checkboxRE1ixhRQlSMeta?.path ?? "/p/jobs/styleguide/checkbox",
    meta: checkboxRE1ixhRQlSMeta || {},
    alias: checkboxRE1ixhRQlSMeta?.alias || [],
    redirect: checkboxRE1ixhRQlSMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/checkbox.vue").then(m => m.default || m)
  },
  {
    name: comboboxjML1MNnV6lMeta?.name ?? "p-jobs-styleguide-combobox",
    path: comboboxjML1MNnV6lMeta?.path ?? "/p/jobs/styleguide/combobox",
    meta: comboboxjML1MNnV6lMeta || {},
    alias: comboboxjML1MNnV6lMeta?.alias || [],
    redirect: comboboxjML1MNnV6lMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/combobox.vue").then(m => m.default || m)
  },
  {
    name: date_45pickerTTWC8WEwriMeta?.name ?? "p-jobs-styleguide-date-picker",
    path: date_45pickerTTWC8WEwriMeta?.path ?? "/p/jobs/styleguide/date-picker",
    meta: date_45pickerTTWC8WEwriMeta || {},
    alias: date_45pickerTTWC8WEwriMeta?.alias || [],
    redirect: date_45pickerTTWC8WEwriMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/date-picker.vue").then(m => m.default || m)
  },
  {
    name: file_45uploadPammO7IgKIMeta?.name ?? "p-jobs-styleguide-file-upload",
    path: file_45uploadPammO7IgKIMeta?.path ?? "/p/jobs/styleguide/file-upload",
    meta: file_45uploadPammO7IgKIMeta || {},
    alias: file_45uploadPammO7IgKIMeta?.alias || [],
    redirect: file_45uploadPammO7IgKIMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/file-upload.vue").then(m => m.default || m)
  },
  {
    name: groupuWAisqKDeeMeta?.name ?? "p-jobs-styleguide-group",
    path: groupuWAisqKDeeMeta?.path ?? "/p/jobs/styleguide/group",
    meta: groupuWAisqKDeeMeta || {},
    alias: groupuWAisqKDeeMeta?.alias || [],
    redirect: groupuWAisqKDeeMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/group.vue").then(m => m.default || m)
  },
  {
    name: indexXt6T92YthaMeta?.name ?? "p-jobs-styleguide",
    path: indexXt6T92YthaMeta?.path ?? "/p/jobs/styleguide",
    meta: indexXt6T92YthaMeta || {},
    alias: indexXt6T92YthaMeta?.alias || [],
    redirect: indexXt6T92YthaMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/index.vue").then(m => m.default || m)
  },
  {
    name: infobox9xUy6x6Hg7Meta?.name ?? "p-jobs-styleguide-infobox",
    path: infobox9xUy6x6Hg7Meta?.path ?? "/p/jobs/styleguide/infobox",
    meta: infobox9xUy6x6Hg7Meta || {},
    alias: infobox9xUy6x6Hg7Meta?.alias || [],
    redirect: infobox9xUy6x6Hg7Meta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/infobox.vue").then(m => m.default || m)
  },
  {
    name: linkgWstdA2Wu9Meta?.name ?? "p-jobs-styleguide-link",
    path: linkgWstdA2Wu9Meta?.path ?? "/p/jobs/styleguide/link",
    meta: linkgWstdA2Wu9Meta || {},
    alias: linkgWstdA2Wu9Meta?.alias || [],
    redirect: linkgWstdA2Wu9Meta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/link.vue").then(m => m.default || m)
  },
  {
    name: notificationinStvDRa3GMeta?.name ?? "p-jobs-styleguide-notification",
    path: notificationinStvDRa3GMeta?.path ?? "/p/jobs/styleguide/notification",
    meta: notificationinStvDRa3GMeta || {},
    alias: notificationinStvDRa3GMeta?.alias || [],
    redirect: notificationinStvDRa3GMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/notification.vue").then(m => m.default || m)
  },
  {
    name: pillsjQnxpuE69Meta?.name ?? "p-jobs-styleguide-pill",
    path: pillsjQnxpuE69Meta?.path ?? "/p/jobs/styleguide/pill",
    meta: pillsjQnxpuE69Meta || {},
    alias: pillsjQnxpuE69Meta?.alias || [],
    redirect: pillsjQnxpuE69Meta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/pill.vue").then(m => m.default || m)
  },
  {
    name: sectionLMQLqSmNANMeta?.name ?? "p-jobs-styleguide-section",
    path: sectionLMQLqSmNANMeta?.path ?? "/p/jobs/styleguide/section",
    meta: sectionLMQLqSmNANMeta || {},
    alias: sectionLMQLqSmNANMeta?.alias || [],
    redirect: sectionLMQLqSmNANMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/section.vue").then(m => m.default || m)
  },
  {
    name: selectjAxg7kKqGHMeta?.name ?? "p-jobs-styleguide-select",
    path: selectjAxg7kKqGHMeta?.path ?? "/p/jobs/styleguide/select",
    meta: selectjAxg7kKqGHMeta || {},
    alias: selectjAxg7kKqGHMeta?.alias || [],
    redirect: selectjAxg7kKqGHMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/select.vue").then(m => m.default || m)
  },
  {
    name: stepperwB4XSGS1iZMeta?.name ?? "p-jobs-styleguide-stepper",
    path: stepperwB4XSGS1iZMeta?.path ?? "/p/jobs/styleguide/stepper",
    meta: stepperwB4XSGS1iZMeta || {},
    alias: stepperwB4XSGS1iZMeta?.alias || [],
    redirect: stepperwB4XSGS1iZMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/stepper.vue").then(m => m.default || m)
  },
  {
    name: tableleGRHtvH5BMeta?.name ?? "p-jobs-styleguide-table",
    path: tableleGRHtvH5BMeta?.path ?? "/p/jobs/styleguide/table",
    meta: tableleGRHtvH5BMeta || {},
    alias: tableleGRHtvH5BMeta?.alias || [],
    redirect: tableleGRHtvH5BMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/table.vue").then(m => m.default || m)
  },
  {
    name: tabsndwBUd3GYKMeta?.name ?? "p-jobs-styleguide-tabs",
    path: tabsndwBUd3GYKMeta?.path ?? "/p/jobs/styleguide/tabs",
    meta: tabsndwBUd3GYKMeta || {},
    alias: tabsndwBUd3GYKMeta?.alias || [],
    redirect: tabsndwBUd3GYKMeta?.redirect,
    component: () => import("/src/packages/manage-job-ads/pages/p/jobs/styleguide/tabs.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TWSRcnAikPMeta?.name ?? "produkte-arbeitgeberprofil-id",
    path: _91id_93TWSRcnAikPMeta?.path ?? "/produkte/arbeitgeberprofil/:id()",
    meta: _91id_93TWSRcnAikPMeta || {},
    alias: _91id_93TWSRcnAikPMeta?.alias || [],
    redirect: _91id_93TWSRcnAikPMeta?.redirect,
    component: () => import("/src/packages/commerce/pages/produkte/arbeitgeberprofil/[id].vue").then(m => m.default || m)
  },
  {
    name: indexM31GbtQmXoMeta?.name ?? "produkte-arbeitgeberprofil",
    path: indexM31GbtQmXoMeta?.path ?? "/produkte/arbeitgeberprofil",
    meta: indexM31GbtQmXoMeta || {},
    alias: indexM31GbtQmXoMeta?.alias || [],
    redirect: indexM31GbtQmXoMeta?.redirect,
    component: () => import("/src/packages/commerce/pages/produkte/arbeitgeberprofil/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93NhJgvXYREyMeta?.name ?? "produkte-stelleninserate-id",
    path: _91id_93NhJgvXYREyMeta?.path ?? "/produkte/stelleninserate/:id()",
    meta: _91id_93NhJgvXYREyMeta || {},
    alias: _91id_93NhJgvXYREyMeta?.alias || [],
    redirect: _91id_93NhJgvXYREyMeta?.redirect,
    component: () => import("/src/packages/commerce/pages/produkte/stelleninserate/[id].vue").then(m => m.default || m)
  },
  {
    name: indexREBqFjvjkTMeta?.name ?? "produkte-stelleninserate",
    path: indexREBqFjvjkTMeta?.path ?? "/produkte/stelleninserate",
    meta: indexREBqFjvjkTMeta || {},
    alias: indexREBqFjvjkTMeta?.alias || [],
    redirect: indexREBqFjvjkTMeta?.redirect,
    component: () => import("/src/packages/commerce/pages/produkte/stelleninserate/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WT9JbdJMv4Meta?.name ?? "produkte-talentsuche-id",
    path: _91id_93WT9JbdJMv4Meta?.path ?? "/produkte/talentsuche/:id()",
    meta: _91id_93WT9JbdJMv4Meta || {},
    alias: _91id_93WT9JbdJMv4Meta?.alias || [],
    redirect: _91id_93WT9JbdJMv4Meta?.redirect,
    component: () => import("/src/packages/commerce/pages/produkte/talentsuche/[id].vue").then(m => m.default || m)
  },
  {
    name: index1S1WiPGi2gMeta?.name ?? "produkte-talentsuche",
    path: index1S1WiPGi2gMeta?.path ?? "/produkte/talentsuche",
    meta: index1S1WiPGi2gMeta || {},
    alias: index1S1WiPGi2gMeta?.alias || [],
    redirect: index1S1WiPGi2gMeta?.redirect,
    component: () => import("/src/packages/commerce/pages/produkte/talentsuche/index.vue").then(m => m.default || m)
  }
]