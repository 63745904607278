import { userConditions } from '@b2b-platform/auth/utils/user';

export default defineNuxtRouteMiddleware(async (to) => {
    const { user, isAuthenticated, isInternalUser } = useUser();

    if (!isAuthenticated || to.meta.isAuthRequired === false) {
        return;
    }

    const { hasAccessToPath } = userConditions(user?.value);

    if (!hasAccessToPath(to.path)) {
        if (isInternalUser.value) {
            return '/p/internal';
        }
        return '/';
    }
});
