const lockedBaseStyles = {
    height: '100%',
    overflow: 'hidden',
    width: '100%',
};

const locked = ref(false);
const scrollY = ref(0);

export function useBodyLock() {
    const rootRef = ref<HTMLElement>();

    const lock = () => {
        if (!rootRef.value || locked.value === true) {
            return;
        }

        locked.value = true;
        scrollY.value = window?.pageYOffset;

        // prevent content shift due to the missing overflow
        rootRef.value!.style.setProperty(
            'padding-right',
            `${window?.innerWidth - rootRef.value.clientWidth}px`,
        );

        Object.entries(lockedBaseStyles).forEach((css) => {
            rootRef.value!.style.setProperty(...css);
        });

        // we need to set the scroll offset as top style
        // to persist the scroll position
        rootRef.value.style.top = `${scrollY.value * -1}px`;
    };

    const unlock = () => {
        if (!rootRef.value || locked.value === false) {
            return;
        }

        locked.value = false;

        // clear lock props
        Object.keys(lockedBaseStyles).forEach((cssAttr) => {
            rootRef.value!.style.setProperty(cssAttr, null);
        });

        rootRef.value!.style.setProperty('padding-right', null);

        // restore scroll position
        rootRef.value.style.setProperty('top', null);
        rootRef.value.scrollTop = scrollY.value;
    };

    onMounted(() => {
        rootRef.value = document.documentElement;
    });

    // clean up
    onUnmounted(() => {
        unlock();
    });

    return {
        lock,
        unlock,
    };
}
