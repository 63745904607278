<script setup lang="ts">
defineProps({
    minimal: {
        default: false,
        type: Boolean,
    },
});

const { toggle, isOpen } = useNavigation();
</script>

<template>
    <!-- eslint-disable tailwindcss/no-custom-classname -->
    <div
        class="k-nav-bar flex items-center justify-between bg-white px-5 md:hidden"
    >
        <button
            type="button"
            class="k-nav-bar__toggle -ml-5 p-5"
            data-qa="navBar toggle"
            @click.stop="toggle"
        >
            <BaseIcon
                :icon-name="isOpen ? 'close' : 'menu'"
                size="large"
                data-qa="navBar toggle icon"
            />
        </button>
        <template v-if="!minimal">
            <NuxtLink to="/">
                <BaseLogo
                    width="6.25rem"
                    data-qa="mainNav logo"
                    title="karriere.at Logo"
                />
            </NuxtLink>
            <BaseCartIcon />
        </template>
    </div>
    <!-- eslint-enable tailwindcss/no-custom-classname -->
</template>

<style lang="scss">
@import '../assets/variables.scss';

:root {
    --nav-bar-height: 60px;
}

.k-nav-bar {
    height: var(--nav-bar-height);

    @media (min-width: $bp-navigation-s) {
        display: none;
    }

    @media print {
        display: none;
    }
}

/**
 * Toggle
 */
.k-nav-bar__toggle {
    z-index: 10;
}

/**
 * Shop Logo
 */
.k-nav-bar__shop-logo {
    width: 90px;
}
</style>
