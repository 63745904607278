export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('decimal-format', {
        beforeMount(el) {
            let inputElement = el;

            if (el.tagName !== 'INPUT') {
                inputElement = el.querySelector('input') || el;
            }

            const handleInput = () => {
                let value = inputElement.value;
                value = value.replace(/[^0-9,.]/g, '');
                value = value.replace('.', ',');

                const parts = value.split(',');
                if (parts.length > 1) {
                    parts[1] = parts[1].slice(0, 2);
                    value = parts.join(',');
                }

                if (value !== inputElement.value) {
                    inputElement.value = value;

                    const event = new Event('input', { bubbles: true });
                    inputElement.dispatchEvent(event);
                }
            };

            inputElement.addEventListener('input', handleInput);
            el.__handleInput = handleInput;
        },

        unmounted(el) {
            let inputElement = el;

            if (el.tagName !== 'INPUT') {
                inputElement = el.querySelector('input') || el;
            }

            const handleInput = el.__handleInput;

            if (handleInput) {
                inputElement.removeEventListener('input', handleInput);

                delete el.__handleInput;
            }
        },
    });
});
