<script setup lang="ts">
import { NuxtLink } from '#components';

import type { NavItem } from '../types/navigation';

const getCollapseId = (() => {
    let tmp = 0;

    return () => {
        const id = tmp;
        tmp += 1;
        return String(id);
    };
})();

const props = defineProps({
    badge: {
        default: null,
        type: String,
    },
    children: {
        default: null,
        type: Array<NavItem>,
    },
    iconName: {
        required: true,
        type: String,
    },
    title: {
        required: true,
        type: String,
    },
    variant: {
        default: null,
        type: String,
    },
});

const id = getCollapseId();
const { isOpen, activeCollapsible, isFHD, close } = useNavigation();
const router = useRouter();
const getLinkActiveState = computed(
    () => (to: string) => router.currentRoute.value.fullPath.includes(to),
);
const someActiveChildExists = computed(() =>
    props.children.some(
        (child) => getLinkActiveState.value(child.to) || child.active,
    ),
);
const activeName = ref(
    isOpen.value && someActiveChildExists.value ? id : undefined,
);
const isActive = computed(() => activeName.value === id);

const handleClick = () => {
    if (isOpen.value) {
        return;
    }

    isOpen.value = true;
    activeName.value = id;
};

const handleChange = () => {
    activeCollapsible.value = id;
};

// Unfortunately Inertia `navigate` events are not emitted
// if the route doesn't change (e.g. `/dashboard` --> click link `/dashboard` won't work),
// therefore we handle closing the navigation ourself
const handleClickLink = () => {
    // only for smaller screens, as the navigation stays open on FHD screens
    if (!isFHD.value) {
        close();
    }
};

// reset if the navigation collapses
watch(isOpen, (value) => {
    if (!value) {
        activeName.value = undefined;
    }
});

// limit max open count for navigation-collapsibles
watch(activeCollapsible, (active) => {
    if (active !== id) {
        activeName.value = undefined;
    }
});

// open collapsible with active children
watch(isOpen, (value) => {
    if (value && someActiveChildExists.value) {
        activeName.value = id;
    }
});
</script>

<template>
    <!-- eslint-disable tailwindcss/no-custom-classname -->
    <div
        :class="[
            'k-navigation-collapse',
            variant === 'collapsed' ? 'mx-auto w-[40px]' : '',
            `k-navigation-collapse--${variant}`,
        ]"
        @click="handleClick"
    >
        <ElCollapse v-model="activeName" accordion @change="handleChange">
            <ElCollapseItem
                :title="title"
                :name="id"
                :disabled="variant === 'collapsed'"
            >
                <template #title>
                    <div
                        :class="[
                            'k-navigation-collapse__header',
                            someActiveChildExists &&
                                'k-navigation-collapse__header--active',
                            'flex',
                            'grow',
                            'items-center',
                            variant === 'collapsed' ? 'p-3' : 'p-5',
                            'bg-gray-50',
                            'text-gray-900',
                        ]"
                    >
                        <BaseTooltip
                            v-if="variant === 'collapsed'"
                            placement="right"
                        >
                            <template #info>
                                {{ title }}
                            </template>
                            <div class="flex">
                                <BaseBadge
                                    v-if="badge"
                                    variant="blue"
                                    rounded="rounded"
                                    class="absolute ml-[-5px] mt-[-20px]"
                                    size="standard"
                                >
                                    <BaseText variant="small">
                                        {{ badge }}
                                    </BaseText>
                                </BaseBadge>
                                <BaseIcon
                                    :class="[
                                        'k-navigation-link__icon',
                                        'shrink-0',
                                        isActive
                                            ? 'text-gray-900'
                                            : 'text-gray-600',
                                    ]"
                                    :icon-name="iconName"
                                    :badge-top="badge"
                                    size="large"
                                    @click.stop="handleClick"
                                />
                            </div>
                        </BaseTooltip>

                        <BaseIcon
                            v-else
                            :icon-name="iconName"
                            size="large"
                            :class="[
                                'k-navigation-collapse__icon',
                                'shrink-0',
                                isActive ? 'text-gray-900' : 'text-gray-600',
                            ]"
                        />

                        <BaseText
                            v-if="variant !== 'collapsed'"
                            as="span"
                            variant="base"
                            class="k-navigation-collapse__title ml-4"
                            :class="{ '!font-semibold': isActive }"
                        >
                            {{ title }}
                        </BaseText>

                        <BaseBadge
                            v-if="variant !== 'collapsed'"
                            variant="blue"
                            size="standard"
                            rounded="rounded"
                            class="ml-3"
                        >
                            <BaseText variant="small">
                                {{ badge }}
                            </BaseText>
                        </BaseBadge>

                        <BaseIcon
                            icon-name="caret-down"
                            :class="[
                                'k-navigation-collapse__arrow',
                                'absolute',
                                'right-5',
                                '',
                                'transition',
                                isActive && 'rotate-180',
                                isActive ? 'text-gray-900' : 'text-gray-600',
                            ]"
                            size="large"
                            :title="title"
                        />
                    </div>
                </template>

                <div class="flex flex-col gap-5">
                    <template v-for="child in children">
                        <component
                            :is="child.as ? 'a' : NuxtLink"
                            v-if="!child.click"
                            :key="child.id"
                            :target="child.external ? '_blank' : '_self'"
                            :href="child.to"
                            :method="child.method"
                            data-gtm-element="BP: Navigation"
                            :data-base-tour="'NavBar' + child.title"
                            :data-gtm-element-detail="child.elementDetail"
                            :class="[
                                'text-gray-600',
                                'pl-9',
                                'k-navigation-collapse__link',
                                'text-left',
                                {
                                    'k-navigation-collapse__link--active':
                                        child.active ||
                                        getLinkActiveState(child.to),
                                    flex: !!child.notification,
                                    'items-center': !!child.notification,
                                },
                            ]"
                            :aria-label="title"
                            @click.stop="handleClickLink"
                        >
                            <BaseText
                                :variant="
                                    child.active || getLinkActiveState(child.to)
                                        ? 'bold'
                                        : 'base'
                                "
                            >
                                {{ child.title }}
                            </BaseText>
                            <div
                                v-if="child.notification"
                                class="ml-auto rounded bg-red-500 px-2 py-1 text-xs font-semibold text-white"
                            >
                                {{ child.notification }}
                            </div>
                        </component>
                        <button v-else :key="child.id" @click="child.click">
                            <BaseText
                                :class="[
                                    'text-gray-600',
                                    'hover:text-gray-900',
                                    'pl-9',
                                    'text-left',
                                ]"
                                variant="base"
                            >
                                {{ child.title }}
                            </BaseText>
                        </button>
                    </template>
                </div>
            </ElCollapseItem>
        </ElCollapse>
    </div>
    <!-- eslint-enable tailwindcss/no-custom-classname -->
</template>

<style>
.k-navigation-collapse {
    position: relative;

    /* prevent content shifts during the navigation animation */
    border-radius: var(--border-radius-standard);
    white-space: nowrap;
}

.k-navigation-collapse__link {
    outline-offset: -1px;
}

.k-navigation-collapse__link:hover,
.k-navigation-collapse__link--active {
    color: var(--color-gray-900);
}

.k-navigation-collapse__header--active * {
    color: var(--color-blue-900);
}

.k-navigation-collapse__header--active {
    color: var(--color-blue-900);
}

.k-navigation-collapse__header:hover * {
    color: var(--color-blue-900);
}

.k-navigation-collapse__header--active .k-navigation-collapse__icon,
.k-navigation-collapse__header:hover .k-navigation-collapse__icon {
    color: var(--color-blue-900);
}

.k-navigation-collapse__header:hover .k-navigation-collapse__icon,
.k-navigation-collapse__header:hover .k-navigation-collapse__title {
    @apply font-semibold;
}

.k-navigation-collapse--collapsed .k-navigation-collapse__arrow {
    display: none;
}

/* element+ overrides */
.k-navigation-collapse .el-collapse-item__arrow {
    display: none;
}

.k-navigation-collapse .el-collapse {
    border: none;
}

.k-navigation-collapse .el-collapse-item__header {
    height: auto;
    outline-offset: -1px;
    border-bottom: none;
    font-weight: normal;
    line-height: initial;
}

.k-navigation-collapse .el-collapse-item__header.focusing {
    outline: var(--color-blue-500) auto 1px;
    outline: -webkit-focus-ring-color auto 1px;
}

.el-collapse-item.is-disabled .el-collapse-item__header {
    cursor: pointer;
}

.k-navigation-collapse .el-collapse-item {
    margin-bottom: 0;
}

.k-navigation-collapse .el-collapse-item__wrap {
    border-bottom: none;
}

.k-navigation-collapse .el-collapse-item__content {
    @apply pt-5 pb-3;
}

.k-navigation-collapse:last-child .el-collapse-item__content {
    @apply pb-0;
}
</style>
