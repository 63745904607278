export default defineNuxtRouteMiddleware((to) => {
    const { isAuthenticated, login } = useUser();

    if (import.meta.client && to.meta.isAuthRequired === false) {
        $fetch(
            `${useRuntimeConfig().public.businessPortal.url}/int/session/auth`,
            {
                credentials: 'include',
            },
        ).then((response) => {
            if (response?.data?.sso && !isAuthenticated.value) {
                // auto-login
                return login({
                    provider: 'b2b-identity-provider',
                    redirectUrl: to.path,
                });
            }
        });
    }

    if (isAuthenticated.value || to.meta.isAuthRequired === false) {
        return;
    }

    if (to.path.startsWith('/p/internal')) {
        return navigateTo('/p/internal/login');
    }

    return login({ provider: 'b2b-identity-provider', redirectUrl: to.path });
});
