import revive_payload_client_4sVQNw7RlN from "/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/src/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import anchorscroll_plugin_H0zwu1Xy9f from "/src/node_modules/nuxt-anchorscroll/dist/runtime/anchorscroll-plugin.mjs";
import plugin_tMGwffvjBc from "/src/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_Jozdw60ZsE from "/src/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/src/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/src/.nuxt/element-plus-injection.plugin.mjs";
import feature_flag_vhsaR08LlA from "/src/packages/base/modules/feature-flag/runtime/plugins/feature-flag.ts";
import chunk_reload_client_UciE0i6zes from "/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import decimalFormat_OGV3hjmlZO from "/src/packages/manage-job-ads/plugins/decimalFormat.ts";
import numberFormat_FPAlXmHvoX from "/src/packages/manage-job-ads/plugins/numberFormat.ts";
import sentry_client_qTEJ4dK9wr from "/src/packages/base/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  anchorscroll_plugin_H0zwu1Xy9f,
  plugin_tMGwffvjBc,
  plugin_Jozdw60ZsE,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  feature_flag_vhsaR08LlA,
  chunk_reload_client_UciE0i6zes,
  decimalFormat_OGV3hjmlZO,
  numberFormat_FPAlXmHvoX,
  sentry_client_qTEJ4dK9wr
]