<script setup lang="ts">
import type { NavItem } from '../types/navigation';

const { user: userInfo } = useUser();
const { routes, abilities, user } = userInfo.value;
const { activeSourcingMessageNotification } = useNavigation();

// Note: The MainNav is locked in the extended variant on smaller devices
const isLocked = useMatchMedia('(max-width: 767px)');
const isFHD = useMatchMedia('(min-width: 1920px');
const { isOpen, open } = useNavigation();
const isExtended = computed(() => isLocked.value || isOpen.value);
const variant = computed(() => (isExtended.value ? 'extended' : 'collapsed'));
const showContactPersonModal = ref(false);

const jobNavItems: NavItem[] = [
    {
        elementDetail: 'Posting overview',
        id: '1',
        title: 'Übersicht',
        to: routes.jobAds.overview,
    },
    {
        // active: usePage().url.startsWith('/jobs/metrics'), // TOOD: what is that?
        elementDetail: 'Job ad report',
        id: '2',
        title: 'Kennzahlen',
        to: routes.jobAds.metrics,
    },
];
if (abilities.job) {
    jobNavItems.push({
        // active: usePage().url.startsWith('/job/'), // TOOD: what is that?
        elementDetail: 'Create new posting',
        id: '3',
        title: 'Neues Inserat schalten',
        to: routes.jobAds.create,
    });
}

const profileNavItems: NavItem[] = [];
if (abilities.invoice) {
    profileNavItems.push({
        elementDetail: 'Invoices',
        id: '2',
        title: 'Rechnungen',
        to: routes.profile.invoices,
    });
}
profileNavItems.push(
    {
        elementDetail: 'Contract',
        id: '3',
        title: 'Verträge',
        to: routes.profile.contracts,
    },
    {
        elementDetail: 'Companydata edit',
        id: '5',
        title: 'Firmendaten bearbeiten',
        to: routes.profile.company,
    },
    {
        id: '6',
        title: 'Einstellungen',
        to: routes.profile.settings,
    },
    {
        as: 'button',
        elementDetail: 'Logout',
        id: '7',
        method: 'post',
        title: 'Abmelden',
        to: '/api/auth/b2b-identity-provider/logout',
    },
);

const activeSourcingNavItems: NavItem[] = [
    {
        title: 'Talentsuche',
        to: '/p/active-sourcing/search?resetSearch=true',
    },
    {
        notification: activeSourcingMessageNotification.value,
        title: 'Nachrichten',
        to: '/p/active-sourcing/messages',
    },
    {
        title: 'Projekte',
        to: '/p/active-sourcing/projects',
    },
    {
        title: 'Matching',
        to: '/p/active-sourcing/matching',
    },
    {
        title: 'Meine Suchen',
        to: '/p/active-sourcing/saved-search',
    },
    {
        title: 'Versionshinweise',
        to: '/p/active-sourcing/releases',
    },
].map((NavItem, index) => ({
    ...NavItem,
    id: `${index + 1}`,
}));

const employerBrandingNavItems: NavItem[] = [];

if (abilities.businessPage) {
    employerBrandingNavItems.push({
        elementDetail: 'Employer Branding',
        id: '1',
        title: 'employer.page',
        to: routes.employerBranding.employerPage,
    });
}

if (abilities.businessPagePlus) {
    employerBrandingNavItems.push({
        id: '2',
        title: 'Arbeitgeberprofil Plus',
        to: routes.employerBranding.employerPagePlus,
    });
    employerBrandingNavItems.push({
        id: '5',
        title: 'Kennzahlen',
        to: routes.employerBranding.metrics,
    });
}

if (abilities.employerBrandingBasic) {
    employerBrandingNavItems.push({
        id: '3',
        title: 'Arbeitgeberprofil Basic',
        to: routes.employerBranding.employerBrandingBasic,
    });
}

if (abilities.employerBrandingPlus) {
    employerBrandingNavItems.push({
        id: '4',
        title: 'Arbeitgeberprofil Plus',
        to: routes.employerBranding.employerBrandingPlus,
    });
    employerBrandingNavItems.push({
        id: '5',
        title: 'Kennzahlen',
        to: routes.employerBranding.metrics,
    });
}

const recruitingServicesNavItems: NavItem[] = [
    {
        elementDetail: 'Recruting Services',
        id: '1',
        title: 'bewerber.manager',
        to: routes.recruitingServices.applicantManager,
    },
];

if (user.company.distributionChannel === 'DIRECT-SALES') {
    recruitingServicesNavItems.push({
        elementDetail: 'Recruting Services',
        id: '1',
        title: 'smart bewerben',
        to: routes.recruitingServices.oneClickApplication,
    });
}

if (user.relatedCompanies && user.relatedCompanies?.length > 1) {
    profileNavItems.splice(2, 0, {
        id: '4',
        title: 'Unternehmen wechseln',
        to: routes.profile.companySwitch,
    });
}

profileNavItems.splice(2, 0, {
    click: () => {
        showContactPersonModal.value = true;
    },
    id: '1',
    title: 'Ansprechperson',
    to: '#',
});

// Watch for changes on activeSourcingMessageNotification
watch(activeSourcingMessageNotification, (newNotification) => {
    // Find the item with title 'Nachrichten'
    const messageItem = activeSourcingNavItems.find(
        (item) => item.title === 'Nachrichten',
    );
    if (messageItem) {
        messageItem.notification = newNotification;
    }
});

onMounted(() => {
    if (isFHD.value) {
        open();
    }
});
</script>

<template>
    <!-- eslint-disable tailwindcss/no-custom-classname -->
    <TheNavShell>
        <!-- main content  -->
        <div class="grow">
            <!-- user details -->
            <div class="k-main-nav__section--no_border text-center">
                <!-- profile image -->
                <!-- src has to be in object notation, as there is a bug in vite at the moment-->
                <img
                    :src="user.avatar ?? `/p/icons/profile-placeholder.svg`"
                    alt="Profilbild"
                    :class="`k-main-nav__profile mx-auto object-cover rounded-full ${
                        !isExtended && 'k-main-nav__profile--collapsed'
                    }`"
                    data-qa="mainNav profile image"
                />
                <div v-if="isExtended" class="k-main-nav__profile-summary mt-3">
                    <BaseText
                        variant="bold"
                        class="text-gray-900"
                        data-qa="mainNav name"
                    >
                        {{ user.firstName }} {{ user.lastName }}
                    </BaseText>

                    <BaseText
                        variant="base"
                        class="text-gray-900"
                        data-qa="mainNav bio"
                    >
                        {{ user.company.fullName }}
                    </BaseText>

                    <BaseText
                        variant="base"
                        class="mb-5 text-gray-600"
                        data-qa="mainNav location"
                    >
                        {{ user.company.mainAddress?.city }}
                    </BaseText>
                </div>
            </div>

            <!-- dashboard and profile links  -->
            <div class="k-main-nav__section">
                <TheNavLink
                    title="Übersicht"
                    icon-name="home"
                    :to="routes.dashboard"
                    :variant="variant"
                    :active="false"
                />
                <TheNavCollapse
                    title="Profil"
                    icon-name="profile"
                    :children="profileNavItems"
                    :variant="variant"
                    data-qa="mainNav profile links"
                    data-gtm-element="BP: Navigation"
                    data-gtm-element-detail="Profile"
                />
            </div>

            <!-- jobs and contingent links -->
            <div class="k-main-nav__section">
                <TheNavCollapse
                    title="Inserate"
                    icon-name="job-ad-edit"
                    :children="jobNavItems"
                    :variant="variant"
                    data-qa="mainNav service links"
                    data-gtm-element="BP: Navigation"
                    data-gtm-element-detail="Posting"
                />
                <TheNavLink
                    title="Guthaben"
                    icon-name="credit"
                    :to="routes.credits"
                    :variant="variant"
                    :active="false"
                    data-gtm-element="BP: Navigation"
                    data-gtm-element-detail="Credit"
                />
            </div>

            <!-- active sourcing, employer.branding and recruiting service links -->
            <div class="k-main-nav__section">
                <TheNavCollapse
                    title="Talentdatenbank"
                    icon-name="magnifier"
                    :children="activeSourcingNavItems"
                    :variant="variant"
                    data-qa="mainNav kandidatensuche link"
                />
                <TheNavCollapse
                    v-if="
                        abilities.businessPage ||
                        abilities.businessPagePlus ||
                        abilities.employerBrandingBasic ||
                        abilities.employerBrandingPlus
                    "
                    title="Employer Branding"
                    icon-name="building"
                    :children="employerBrandingNavItems"
                    :variant="variant"
                    data-qa="mainNav employer branding link"
                />
                <TheNavCollapse
                    title="Recruiting Services"
                    icon-name="tool-box"
                    :children="recruitingServicesNavItems"
                    :variant="variant"
                    data-qa="mainNav recruiting services link"
                />
            </div>
        </div>

        <!-- footer  -->
        <div>
            <div class="k-main-nav__section mt-6">
                <!-- Temporary solution. Remove when we introduce i18n globally. -->
                <slot />
                <TheNavLink
                    title="FAQs"
                    icon-name="speech-bubble"
                    :to="routes.faqs"
                    :variant="variant"
                    :external="true"
                    data-qa="mainNav faqs"
                    data-gtm-element="BP: Navigation"
                    data-gtm-element-detail="FAQs"
                />
                <TheNavLink
                    title="AGB und Impressum"
                    icon-name="paper"
                    :to="routes.agb"
                    :variant="variant"
                    :external="true"
                    data-qa="mainNav agb"
                    data-gtm-element="BP: Navigation"
                    data-gtm-element-detail="Conditions and Imprint"
                />
            </div>
        </div>
        <TheContactPersonModal
            :open="showContactPersonModal"
            data-gtm-element="BP: Navigation"
            data-gtm-element-detail="Contactperson"
            :assigned-sales-user="user.company.assignedSalesUser"
            @close-modal="showContactPersonModal = false"
        />
    </TheNavShell>
    <!-- eslint-enable tailwindcss/no-custom-classname -->
</template>

<style lang="scss">
/**
* Profile
*/
.k-main-nav__profile {
    width: 60px;
    height: 60px;
    transition:
        width 0.08s ease-out,
        height 0.08s ease-out;
    transition-delay: 0.12s;
}

.k-main-nav__profile--collapsed {
    width: 40px;
    height: 40px;
    transition-delay: 0s;
}

.k-main-nav__profile-summary {
    min-width: calc(320px - 2 * var(--space-5));
}

/**
* Common
*/
.k-main-nav__section {
    display: flex;
    flex-direction: column;
    gap: var(--space-3);
    margin-bottom: var(--space-6);
    padding-top: var(--space-6);
    border-top: 1px solid var(--color-gray-100);
}

.k-main-nav__section--no_border {
    gap: var(--space-3);
    margin-bottom: var(--space-7);
    padding-top: var(--space-6);
}

// concatenated selector in order to compensate the sibling selector above
.k-main-nav__section.k-main-nav__section--without-border {
    padding-top: 0;
    border: none;
}

.k-main-nav__section:last-child {
    margin-bottom: 0;
}
</style>
