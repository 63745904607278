<script setup lang="ts">
export type IconSize =
    | 'small'
    | 'medium'
    | 'medium-large'
    | 'large'
    | 'larger'
    | 'huge';

const props = defineProps({
    badgeTop: {
        default: '',
        type: String,
    },
    disabled: {
        default: false,
        type: Boolean,
    },
    iconName: {
        required: true,
        type: String,
    },
    size: {
        default: 'standard',
        type: String as PropType<IconSize>,
    },
    stroke: {
        default: 'normal',
        type: String,
    },
});

const icon = ref('');
if (isClient) {
    watch(() => props.iconName, fetchIcon, { immediate: true });
}
async function fetchIcon() {
    await fetch(`/p/icons/${props.iconName}.svg`)
        .then((response) => response.text())
        .then((svg) => {
            icon.value = svg;
        })
        .catch((error) =>
            console.error(`Error fetching icon: ${props.iconName}`, error),
        );
}
</script>

<template>
    <!-- eslint-disable vue/no-v-html tailwindcss/no-custom-classname -->
    <svg
        :class="[
            'k-icon',
            `k-icon--${size}`,
            disabled && 'k-icon--disabled',
            `k-icon--${stroke}`,
        ]"
        v-html="icon"
    />
    <!-- eslint-enable vue/no-v-html tailwindcss/no-custom-classname -->
</template>

<style>
.k-icon {
    overflow: hidden;
    width: 1em;
    height: 1em;
}

.k-icon--small {
    font-size: 14px;
}

.k-icon--medium {
    font-size: 18px;
}

.k-icon--medium-large {
    font-size: 20px;
}

.k-icon--large {
    font-size: 24px;
}

.k-icon--larger {
    font-size: 32px;
}

.k-icon--huge {
    font-size: 56px;
}

.k-icon--thick {
    stroke-width: 1.5px;
}

.k-icon--thin {
    stroke-width: 0.75px;
}

.k-icon--disabled {
    @apply opacity-25 cursor-default;
}
</style>
