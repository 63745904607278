import validate from "/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import shop_45redirect_45global from "/src/packages/manage-job-ads/middleware/shop-redirect.global.ts";
import user_45global from "/src/packages/manage-job-ads/middleware/user.global.ts";
import debug_45global from "/src/packages/base/middleware/debug.global.ts";
import package_45protection_45global from "/src/packages/base/middleware/package-protection.global.ts";
import auth_45global from "/src/packages/auth/middleware/auth.global.ts";
import internal_45global from "/src/packages/auth/middleware/internal.global.ts";
import manifest_45route_45rule from "/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  shop_45redirect_45global,
  user_45global,
  debug_45global,
  package_45protection_45global,
  auth_45global,
  internal_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "internal-user-ad-permission": () => import("/src/packages/manage-job-ads/middleware/internal-user-ad-permission.ts"),
  "jam-form-step": () => import("/src/packages/manage-job-ads/middleware/jam-form-step.ts")
}