import type { FeatureFlags } from '../../types';

export default defineNuxtPlugin(async () => {
    const featureFlags = await $fetch<FeatureFlags>('/api/feature-flag');

    return {
        provide: {
            featureFlags,
        },
    };
});
