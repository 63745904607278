<script setup lang="ts">
const showTooltip = useMatchMedia('(min-width: 1280px)');
const isFHD = useMatchMedia('(min-width: 1920px');
const { toggle, isOpen } = useNavigation();
const isExtended = computed(() => isOpen.value);

const { user: userInfo } = useUser();
const { routes } = userInfo.value;

onMounted(() => {
    if (isFHD.value) {
        isOpen.value = true;
    }
});
</script>

<template>
    <!-- eslint-disable tailwindcss/no-custom-classname -->
    <div class="k-shell-nav__focus-wrapper">
        <div
            :class="[
                'k-shell-nav',
                !isExtended && 'k-shell-nav--closed',
                'h-full',
            ]"
        >
            <div :class="['k-shell-nav__inner', 'h-full', 'bg-white']">
                <!-- toggle -->
                <button
                    :class="[
                        'k-shell-nav__toggle',
                        isExtended && 'k-shell-nav__toggle--inverted',
                        'p-2',
                        'rounded-full',
                        'bg-white',
                        'drop-shadow',
                    ]"
                    data-qa="mainNav toggle"
                    title="Navigation Ein- und Ausklappen"
                    @click="toggle"
                >
                    <BaseTooltip v-if="showTooltip" placement="right">
                        <template #info>
                            {{ isExtended ? 'Einklappen' : 'Ausklappen' }}
                        </template>
                        <BaseIcon icon-name="arrow-to-right" size="large" />
                    </BaseTooltip>
                    <BaseIcon v-else icon-name="arrow-to-right" size="large" />
                </button>

                <div
                    :class="[
                        'k-shell-nav__main',
                        'h-full',
                        'flex',
                        'flex-col',
                        isExtended && 'px-5',
                    ]"
                >
                    <!-- header -->
                    <NuxtLink
                        :class="[
                            'k-shell-nav__header',
                            'mb-9',
                            'shrink-0',
                            'w-[40px]',
                            !isExtended && 'mx-auto',
                            isExtended && 'px-5',
                        ]"
                        :to="routes.dashboard"
                        external
                    >
                        <!-- brand -->
                        <div class="relative">
                            <BaseLogo
                                :class="[
                                    'k-shell-nav__brand',
                                    !isExtended &&
                                        'k-shell-nav__brand--collapsed',
                                    'mb-2',
                                ]"
                                data-qa="mainNav logo"
                                title="karriere.at Logo"
                            />

                            <BaseText
                                variant="bold"
                                :class="[
                                    'k-shell-nav__brand-tagline',
                                    !isExtended &&
                                        'k-shell-nav__brand-tagline--collapsed',
                                    'text-gray-900',
                                    'absolute',
                                ]"
                                data-qa="mainNav text"
                            >
                                Business Portal
                            </BaseText>
                        </div>
                    </NuxtLink>
                    <slot />
                </div>
            </div>
        </div>
    </div>
    <!-- eslint-enable tailwindcss/no-custom-classname -->
</template>

<style lang="scss">
$bp-navigation-s: 768px;
$bp-navigation-l: 1920px;

$main-nav-width: 352px;
$main-nav-width--small: 320px;
$main-nav-width--collapsed: 72px;

/* todo: unify open/extended/small states @joh */

.k-shell-nav {
    --k-shell-nav-width: #{$main-nav-width--small};

    // overlap content
    @media (min-width: $bp-navigation-s) {
        --k-shell-nav-width: #{$main-nav-width};

        width: $main-nav-width--collapsed;
    }

    // push content
    @media (min-width: $bp-navigation-l) {
        width: auto;
    }
}

.k-shell-nav--closed {
    --k-shell-nav-width: #{$main-nav-width--collapsed};
}

/**
 * Focus Wrapper
 */
.k-shell-nav__focus-wrapper,
.k-shell-nav__focus-wrapper > div:nth-child(2) /** actual k-shell-nav element*/ {
    height: 100%;
}

/**
 * Inner - where the magic happens ;)
 */
.k-shell-nav__inner {
    position: relative;
    width: var(--k-shell-nav-width);
    transition: width 0.2s ease-out;
}

.k-shell-nav:hover .k-shell-nav__inner {
    will-change: width;
}

/**
 * Main
 */
.k-shell-nav__main {
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 60px;
    padding-bottom: var(--space-7);
    overscroll-behavior: contain;

    @media (min-width: $bp-navigation-s) {
        padding-top: var(--space-7);
    }
}

/**
 * Header
 */
.k-shell-nav__header {
    // header will be displayed in a separate nav-bar on mobile devices
    display: none;
    white-space: nowrap;

    @media (min-width: $bp-navigation-s) {
        display: block;
    }
}

/**
 * Brand
 */
.k-shell-nav__brand {
    width: 160px;
    transition:
        clip-path 0.2s ease-out,
        transform 0.3s ease-out;
    transform: translate(0, 0);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.k-shell-nav__brand--collapsed {
    transform: translate(12px, 0);
    clip-path: polygon(0 0, 18px 0, 18px 100%, 0% 100%);
}

.k-shell-nav__brand-tagline {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.1s ease-out;
    transition-delay: 0.1s;
}

.k-shell-nav__brand-tagline--collapsed {
    visibility: hidden;
    opacity: 0;
    transition-delay: 0s;
}

/**
 * Toggle (tablet)
 */
.k-shell-nav__toggle {
    position: absolute;
    right: 0;
    z-index: 1; // workaround: somehow safari does not create a new stacking context without the z-index here
    display: none;
    transition: all 0.5s cubic-bezier(0.62, 0.61, 0.45, 1.15);
    // center button horizontally on the right nav edge and bring up by the amount of the line-height from the tag-line
    transform: translate(50%, var(--space-12));

    @media (min-width: $bp-navigation-s) {
        display: block;
    }
}

.k-shell-nav__toggle--inverted {
    // center button horizontally on the right nav edge and bring up by the amount of the line-height from the tag-line
    transform: translate(50%, var(--space-12)) rotate(180deg);
}
</style>
