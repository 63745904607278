<script setup lang="ts">
const error = useError();

definePageMeta({
    layout: 'content',
});

usePageTitle().append('Seite wurde nicht gefunden');

const showErrorDetails = computed(() => process.env.NODE_ENV !== 'production');
</script>

<template>
    <BaseLayoutFull>
        <NuxtLoadingIndicator />
        <template #aside>
            <TheSidebar />
        </template>
        <ErrorPage>
            <template #errorMessage>
                <div class="mx-auto">
                    <div class="text-center">
                        <span
                            class="text-xl font-semibold text-gray-900 sm:text-2xl"
                        >
                            Seite wurde nicht gefunden
                        </span>
                    </div>
                    <details v-if="showErrorDetails" class="mt-3" open>
                        <summary>Details</summary>
                        <span
                            v-for="(value, key) in error"
                            :key="key"
                            class="block text-xs"
                            >{{ key }} : {{ value }}{{ '\n' }}</span
                        >
                    </details>
                </div>
            </template>
        </ErrorPage>
    </BaseLayoutFull>
</template>
