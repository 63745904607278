import { type Abilities, userConditions } from '@b2b-platform/auth/utils/user';

import type { ExternalUser, InternalUser } from '../entities/user';

const useUserState = () =>
    useState<ExternalUser | InternalUser | null>('auth-user', () => null);

const getUser = async () => {
    const { invoke } = useApi();
    useUserState().value = await invoke<ExternalUser | InternalUser | null>(
        '/api/auth/me',
    );
};

export const useUser = () => {
    const state = useUserState();
    const config = useRuntimeConfig().public;
    return {
        abilities: computed<Abilities>(
            () => userConditions(state.value).abilities,
        ),
        fetch: getUser,
        isAuthenticated: computed(
            () => userConditions(state.value).isAuthenticated,
        ),
        isExternalUser: computed(
            () => userConditions(state.value).isExternalUser,
        ),
        isInternalUser: computed(
            () => userConditions(state.value).isInternalUser,
        ),
        login: ({
            provider,
            redirectUrl,
        }: {
            provider?:
                | 'b2b-identity-provider'
                | 'internal-identity-provider'
                | 'mock';
            redirectUrl?: string;
        } = {}) => {
            return navigateTo(
                {
                    path: `${config.base.url}/api/auth/${provider}/login`,
                    query: {
                        redirectUrl,
                    },
                },
                { external: true },
            );
        },
        logout: ({
            provider,
            redirectUrl,
        }: {
            provider?: 'b2b-identity-provider';
            redirectUrl?: string;
        } = {}) => {
            return navigateTo(
                {
                    path: `${config.base.url}/api/auth/${provider}/logout`,
                    query: {
                        redirectUrl,
                    },
                },
                { external: true },
            );
        },
        user: computed<ExternalUser | InternalUser | null>(() => state.value),
    };
};
