<script setup>
useHead({
    bodyAttrs: {
        class: 'bg-gray-50 text-gray-900 overflow-hidden fixed print:static md:static left-0 right-0',
    },
});
const { snackbars } = useSnackbar();
</script>

<template>
    <div class="h-svh">
        <BaseNavMobile />
        <div class="flex h-[calc(100%-60px)] md:h-full">
            <aside>
                <slot name="aside" />
            </aside>
            <header v-if="$slots.header">
                <slot name="header" />
            </header>
            <main class="h-full max-w-full grow overflow-x-hidden">
                <slot />
            </main>
        </div>
    </div>
    <BaseSnackbar v-if="snackbars.length" :snackbars="snackbars" />
</template>
