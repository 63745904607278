const isOpen = ref(false);
const activeCollapsible = ref('');

const activeSourcingMessageNotification = ref<string>('');

export function useNavigation() {
    const { lock, unlock } = useBodyLock();
    const isFHD = useMatchMedia('(min-width: 1920px');

    const open = () => {
        isOpen.value = true;
    };

    const close = () => {
        isOpen.value = false;
    };

    const toggle = () => {
        if (isOpen.value) {
            close();
        } else {
            open();
        }
    };

    watch(isOpen, (value) => {
        if (isFHD.value) return;

        if (value) {
            lock();
        } else {
            unlock();
        }
    });

    return {
        activeCollapsible,
        activeSourcingMessageNotification,
        close,
        isFHD,
        isOpen,
        open,
        toggle,
    };
}
