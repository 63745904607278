/**
 * Redirects to the publish page if the jobAdId is present in the query params
 * i.e. /p/jobs?jobAdId=123 -> /p/jobs/123/publish
 */

export default defineNuxtRouteMiddleware(async (to) => {
    const jobAdId = to.query.jobAdId;

    if (to.path === `/p/jobs` && jobAdId) {
        return `/p/jobs/${jobAdId}/publish`;
    }

    return;
});
