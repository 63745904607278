<script setup lang="ts">
import type { Snackbar } from '#build/composables/snackbar';

defineProps({
    snackbars: {
        default: () => [],
        required: true,
        type: Array as PropType<Snackbar[]>,
    },
});

const { resetTimeout, clearTimeout } = useSnackbar();
</script>

<template>
    <Teleport to="body">
        <div
            class="pointer-events-none absolute inset-x-0 bottom-0 flex flex-col items-center"
        >
            <span
                v-for="snackbar in snackbars"
                :key="snackbar.message"
                role="alert"
                aria-live="assertive"
                class="pointer-events-auto z-50 mb-7 max-w-2xl rounded p-5 text-white shadow-lg"
                :class="{
                    'bg-blue-900': snackbar.type === 'Info',
                    'bg-red-700': snackbar.type === 'Error',
                }"
                data-qa="snackbar"
                @mouseenter="clearTimeout"
                @mouseleave="resetTimeout"
            >
                {{ snackbar.message }}
            </span>
        </div>
    </Teleport>
</template>
