<script setup lang="ts">
const colors = {
    blue: 'text-gray-900',
    gray: 'text-gray-600',
    green: 'text-green-800',
};

defineProps({
    as: {
        default: 'nuxt-link',
        type: String as PropType<'button' | 'nuxt-link' | 'a' | 'span'>,
    },
    color: {
        default: 'green',
        type: String,
    },
    disabled: {
        default: false,
        type: Boolean,
    },
    href: {
        default: null,
        type: String,
    },
    target: {
        default: null,
        type: String,
    },
});
</script>

<template>
    <!-- eslint-disable tailwindcss/no-custom-classname -->
    <Component
        :is="as"
        :class="[
            'k-link',
            'inline-flex',
            'items-center',
            'text-base',
            'font-regular',
            disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            (color === 'gray' || color === 'blue') && 'underline',
            disabled ? 'text-gray-400' : colors[color],
        ]"
        :disabled="as === 'button' ? disabled : undefined"
        :data-disabled="disabled"
        :href="as === 'a' || as === 'nuxt-link' ? href : undefined"
        :target="as === 'a' || as === 'nuxt-link' ? target : undefined"
        :type="as === 'button' && 'button'"
    >
        <slot />
    </Component>
    <!-- eslint-enable tailwindcss/no-custom-classname -->
</template>

<style lang="scss">
.k-link:not([data-disabled='true']):not(:disabled):hover {
    color: var(--color-green-800);
    text-decoration: underline;
}
</style>
