export const useGtm = () => {
    const { id } = useRuntimeConfig().public.gtm;
    const proxy = useScriptGoogleTagManager({
        id,
        scriptOptions: {
            trigger: 'manual',
        },
    });
    if (id) {
        const consentCookieIsSet = Boolean(useCookie('OptanonConsent').value);
        if (!consentCookieIsSet) {
            useOnFirstUserInteraction(proxy.load);
        } else {
            proxy.load();
        }
    }

    return { dataLayer: proxy.dataLayer, gtm: proxy };
};
