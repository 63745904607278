type SnackbarType = 'Error' | 'Info';

export type Snackbar = {
    message: string;
    type: SnackbarType;
};
const queue = ref<Snackbar[]>([]);
const snackbars = ref<Snackbar[]>([]);
const timeoutIds: number[] = [];

export const useSnackbar = () => {
    const SNACKBAR_DURATION = 2200;

    const add = (snackbar: Snackbar) => {
        if (snackbars.value.length >= 2) {
            queueSnackbar(snackbar);
            return;
        }
        showSnackbar(snackbar);
        setTimeoutForRemoval(SNACKBAR_DURATION);
    };
    const setTimeoutForRemoval = (duration: number) => {
        timeoutIds.push(
            window?.setTimeout(() => {
                removeOldestSnackbar();
                dequeueSnackbar();
                if (snackbars.value.length) resetTimeout();
            }, duration),
        );
    };
    const showSnackbar = (snackbar: Snackbar) => {
        snackbars.value.push(snackbar);
    };
    const queueSnackbar = (snackbar: Snackbar) => {
        queue.value.push(snackbar);
    };
    const dequeueSnackbar = () => {
        const oldestQueuedSnackbar = queue.value.shift();
        if (oldestQueuedSnackbar) {
            showSnackbar(oldestQueuedSnackbar);
        }
    };
    const removeOldestSnackbar = () => {
        snackbars.value.shift();
    };

    const resetTimeout = () => {
        clearTimeout();
        setTimeoutForRemoval(SNACKBAR_DURATION);
    };

    const clearTimeout = () => {
        timeoutIds.forEach((timeoutId) => {
            window?.clearTimeout(timeoutId);
        });
    };

    return {
        add,
        clearTimeout,
        resetTimeout,
        snackbars,
    };
};
