<script setup lang="ts">
/**
 * The modal component provides a solid foundation for creating dialogs, popovers, lightboxes, or whatever else.
 */

const props = defineProps({
    body: {
        default: null,
        type: String,
    },
    center: {
        default: false,
        type: Boolean,
    },
    className: {
        default: null,
        type: String,
    },
    closeButtonProps: {
        default: null,
        type: Object,
    },
    closeOnClickModal: {
        default: true,
        type: Boolean,
    },
    closeOnPressEscape: {
        default: true,
        type: Boolean,
    },
    dataQa: {
        default: null,
        type: String,
    },
    fullscreen: {
        default: false,
        type: Boolean,
    },
    fullscreenContent: {
        default: false,
        type: Boolean,
    },
    maxWidth: {
        default: null,
        type: String,
    },
    showClose: {
        default: true,
        type: Boolean,
    },
    stickyFooter: {
        default: false,
        type: Boolean,
    },
    textCenter: {
        default: false,
        type: Boolean,
    },
    title: {
        default: null,
        type: String,
    },
    visible: {
        default: false,
        type: Boolean,
    },
});

const emit = defineEmits(['update:visible']);
const slots = useSlots();

// TODO not fixed px value!
const isLargeDevice = useMatchMedia('(min-width: 1000px)');
// prevent extra space from unnecessary element+ footer div
const hasFooterSlot = computed(() => slots.footer);
const localVisible = computed({
    get: () => !!props.visible,
    // sync local state and proxy v-model value
    set: (isVisible) => emit('update:visible', isVisible),
});

const handleClickClose = () => {
    localVisible.value = false;
};
</script>

<template>
    <!-- eslint-disable tailwindcss/no-custom-classname -->
    <ElDialog
        v-model="localVisible"
        :class="
            [
                'k-modal',
                !fullscreenContent && 'pt-6 md:pt-10',
                'overflow-hidden',
                textCenter && 'text-center',
                stickyFooter && 'k-modal--sticky-footer',
                fullscreen && 'k-modal--fullscreen',
                !stickyFooter && !fullscreenContent && 'pb-6 md:pb-10',
                maxWidth && 'k-modal--custom-width',
                className,
                maxWidth,
            ]
                .filter(Boolean)
                .join(' ')
        "
        :show-close="false"
        :close-on-click-modal="closeOnClickModal"
        :close-on-press-escape="closeOnPressEscape"
        :align-center="!isLargeDevice || center"
        append-to-body
        data-qa="modal"
        :title="title"
    >
        <!-- custom close icon -->
        <button
            v-if="showClose"
            type="button"
            :class="[
                'absolute',
                'right-5',
                'top-5',
                'k-modal__close',
                'hover:text-green-900',
            ]"
            v-bind="closeButtonProps"
            @click="handleClickClose()"
        >
            <BaseIcon icon-name="close" size="large" />
        </button>

        <template #header>
            <div :class="['px-6', 'md:px-10']">
                <BaseHeadline v-if="title" class="mb-2" variant="h3">
                    {{ title }}
                </BaseHeadline>

                <!-- alternative more flexible title slot -->
                <slot name="title" />
            </div>
        </template>

        <div :class="[!fullscreenContent && 'px-8 md:px-10']">
            <BaseText v-if="body" variant="base" class="text-gray-900">
                {{ body }}
            </BaseText>
            <!-- alternative more flexible body slot -->
            <slot name="body" />

            <slot />
        </div>

        <template v-if="hasFooterSlot" #footer>
            <slot name="footer" />
        </template>
    </ElDialog>
    <!-- eslint-enable tailwindcss/no-custom-classname -->
</template>

<style>
.k-modal {
    --el-dialog-margin-top: 296px;
    @apply overflow-y-auto max-h-[80vh] h-fit;
    width: calc(
        100% - (var(--space-4) * 2)
    ); /* ensure minimum spacing left and right of modal on small screens */
}

.k-modal:not(.k-modal--custom-width) {
    @apply max-w-[736px];
}

.k-modal .el-dialog__header,
.k-modal .el-dialog__body {
    @apply m-0 p-0 break-normal;
    hyphens: auto;
}

.k-modal--sticky-footer .el-dialog__footer {
    @apply sticky bottom-0 left-0 p-6 bg-white border-t border-gray-200;

    box-shadow: 0 0 15px 0 rgb(0 0 0 / 20%);
}

.k-modal--fullscreen {
    @apply w-full h-full max-h-[100%] rounded-none !important;
}
</style>
