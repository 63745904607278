type $Fetch = typeof $fetch;
type Path = Parameters<$Fetch>[0];
type ApiResponseTypeGuard = { data: unknown; meta?: Record<string, unknown> };

const RETRY_COUNT = 3;
const RETRY_DELAY = 100;
const RETRY_STATUS_CODES = [401, 408, 409, 425, 429, 500, 502, 503, 504];

const isValidResponse = (
    response: any,
): response is { data: unknown; meta?: Record<string, unknown> } => {
    return response && typeof response === 'object' && 'data' in response;
};

export const useApiFetch = async <ApiResponse extends ApiResponseTypeGuard>(
    path: Path,
    {
        lazy = false,
        query,
    }: {
        lazy?: boolean;
        query?: Record<string, unknown>;
    } = {},
) => {
    const headers = useRequestHeaders(['cookie']) as HeadersInit;
    const { data, ...rest } = await useFetch<ApiResponse>(path, {
        headers,
        lazy,
        query,
        retry: RETRY_COUNT,
        retryDelay: RETRY_DELAY,
        retryStatusCodes: RETRY_STATUS_CODES,
        server: !lazy,
    });

    return {
        data: computed<ApiResponse['data'] | null>(() =>
            isValidResponse(data.value) ? data.value.data : null,
        ),
        meta: computed<ApiResponse['meta'] | null>(() =>
            isValidResponse(data.value) ? data.value.meta : null,
        ),
        ...rest,
    };
};

export const useApi = () => {
    const headers = useRequestHeaders(['cookie']) as HeadersInit;
    return {
        invoke: <ApiResponse extends ApiResponseTypeGuard>(
            path: Path,
            options?: Parameters<$Fetch>[1],
        ) => {
            let retryOptions: {
                retry?: number;
                retryDelay?: number;
                retryStatusCodes?: number[];
            } = {};
            if (options?.method?.toUpperCase() === 'GET') {
                retryOptions = {
                    retry: RETRY_COUNT,
                    retryDelay: RETRY_DELAY,
                    retryStatusCodes: RETRY_STATUS_CODES,
                };
            }

            return $fetch<ApiResponse>(path, {
                ...retryOptions,
                ...options,
                headers,
            });
        },
    };
};
