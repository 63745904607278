import type { JamServiceResponse } from '../composables/useJamService';
import type { JamForm } from '../types/jamForm';

export default defineNuxtRouteMiddleware(async (to) => {
    const { user, isInternalUser } = useUser();

    if (!user || !user.value) {
        return;
    }

    const jobAdId = to.params.id;

    if (to.path.startsWith(`/p/jobs/${jobAdId}`)) {
        const { data: jobAdData } = await useFetch<JamServiceResponse<JamForm>>(
            isInternalUser.value
                ? `/api/manage-job-ads/internal/job-ads/${jobAdId}`
                : `/api/manage-job-ads/external/job-ads/${jobAdId}`,
            {
                method: 'get',
                server: true,
            },
        );

        if (
            user.value?.user?.company?.id !==
            jobAdData.value?.data?.meta?.companyId
        ) {
            return '/p/jobs';
        }
    }
});
