import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter();
    const {
        public: { sentry },
    } = useRuntimeConfig();

    if (!sentry.dsn) {
        return;
    }

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: sentry.environment,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.ModuleMetadata(),
            new Sentry.Replay(),
        ],
        release: sentry.release,
        replaysOnErrorSampleRate: sentry.replaysOnErrorSampleRate,
        replaysSessionSampleRate: 1.0,
        tracePropagationTargets: [
            'localhost',
            'karriere.at',
            'karriere.dev',
            'karriere.local',
        ],
        tracesSampleRate: sentry.tracesSampleRate,
    });
});
