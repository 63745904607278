export const useMatchMedia = (query: string) => {
    if (typeof window === 'undefined') return ref(false);

    const mql = window.matchMedia(query);
    const match = ref(mql.matches);

    const handleChange = (event: MediaQueryListEvent) => {
        match.value = event.matches;
    };

    onMounted(() => {
        mql?.addEventListener('change', handleChange);
    });

    return match;
};
