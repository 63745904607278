import type {
    ExternalUser,
    InternalUser,
} from '@b2b-platform/auth/entities/user';
import { useJamServiceJobAds } from '@b2b-platform/manage-job-ads/composables/useJamServiceJobAds';
import type { JamForm } from '@b2b-platform/manage-job-ads/types/jamForm';
import type { H3Event } from 'h3';
import type { NitroFetchOptions } from 'nitropack';

export const internalUserGroups: { [key: string]: string } = {
    admin: 'b9160f7f-b317-4fea-9c7c-5775966ff1b1',
    adminExternal: 'd1b2dec1-898c-4308-9a53-caaa3ef011eb',
    customerCare: 'f923ae90-4863-4668-894c-2622e511119d',
    interface: '857cdf1e-ceca-409f-b2c8-eb37d1e32ea9',
    login: 'b64f3b04-976e-402c-9fc6-347fc0f265e9',
    qa: '1515c4f6-b3da-4d41-987f-41e2d2431c75',
    readOnly: '786ad2f3-8e6c-4f30-b499-dc3a14060ceb',
    sales: '4e2310da-04a4-42cf-a77b-715739c85078',
    serviceEntry: '10702a6a-b07e-4664-887f-6d434f08feca',
    support: '1eab48ba-871a-48a2-9a76-2b0b13c3867c',
};

export type Abilities = {
    adsList: boolean;
    adActionsMaintenance: boolean;
    adActionsStatus: boolean;
    internalPanel: boolean;
    manipulateGroups: boolean;
    qaPages: boolean;
    editAdEditable: boolean;
    editAdNonEditable: boolean;
};

export const userConditions = (data: ExternalUser | InternalUser | null) => {
    const isAuthenticated = !!data;
    const isExternalUser = data?.roles?.includes('external');
    const isInternalUser = data?.roles?.includes('internal');

    const hasOneOfGroups = (
        groupNames: string[],
        restrict: boolean = false,
    ): boolean => {
        if (isExternalUser) {
            return false;
        }

        // Exclude adminExternal from all groups on production
        if (process.env.NUXT_APP_ENV === 'production') {
            groupNames = groupNames.filter(
                (group) => group !== 'adminExternal',
            );
        }

        const groups = restrict
            ? (data as InternalUser)?.groupsByLogin ?? []
            : (data as InternalUser)?.groups ?? [];
        return groupNames.some((key) =>
            groups.includes(internalUserGroups[key]),
        );
    };

    const hasOneOfAllGroupsExcept = (
        groupNames: string[],
        restrict: boolean = false,
    ): boolean =>
        hasOneOfGroups(
            Object.keys(internalUserGroups).filter(
                (item) => !groupNames.includes(item),
            ),
            restrict,
        );

    const abilities: Abilities = {
        adActionsMaintenance: hasOneOfGroups([
            'sales',
            'customerCare',
            'support',
            'admin',
            'adminExternal',
        ]),
        adActionsStatus: hasOneOfGroups([
            'sales',
            'customerCare',
            'qa',
            'support',
            'admin',
            'adminExternal',
        ]),
        adsList: hasOneOfAllGroupsExcept(['login']),
        editAdEditable: hasOneOfGroups(['admin', 'qa', 'adminExternal']),
        editAdNonEditable: hasOneOfGroups(['admin', 'adminExternal']),
        internalPanel: hasOneOfAllGroupsExcept([], true),
        manipulateGroups: hasOneOfGroups(['admin', 'adminExternal'], true),
        qaPages: hasOneOfGroups(['admin', 'qa', 'adminExternal']),
    };

    const shouldBeIgnored = (path: string) =>
        path.startsWith('/api/auth/') ||
        path.startsWith('/api/auth/internal-identity-provider') ||
        path.startsWith('/p/internal/login') ||
        path.startsWith('/p/debug');

    const isApiPath = (path: string) => path.startsWith('/api/');

    const hasAccessToApiPath = async (event: H3Event): Promise<boolean> => {
        const path = getRequestURL(event).pathname;
        const method = event.method;
        const isManageJobAdsApiPath = path.startsWith('/api/manage-job-ads/');
        if (!isManageJobAdsApiPath) {
            return true;
        }

        const isExternalUserApiPath = path.startsWith(
            '/api/manage-job-ads/external',
        );

        if (isExternalUserApiPath && !isExternalUser) {
            return false;
        }

        const isInternalUserApiPath = path.startsWith(
            '/api/manage-job-ads/internal',
        );
        if (isInternalUserApiPath && !abilities.internalPanel) {
            return false;
        }

        const isAdListApiPath =
            path.startsWith('/api/manage-job-ads/internal/companies') ||
            path.startsWith('/api/manage-job-ads/internal/job-ads');
        if (isAdListApiPath && !abilities.adsList) {
            return false;
        }

        const isQaApiPath = path.startsWith('/api/manage-job-ads/internal/qa');
        if (isQaApiPath && !abilities.qaPages) {
            return false;
        }

        const isAdActionStatusApiPath = new RegExp(
            /^\/api\/manage-job-ads\/internal\/job-ads\/[^/]+\/(deactivate|reactivate|lock|unlock)/,
        ).test(path);
        if (isAdActionStatusApiPath && !abilities.adActionsStatus) {
            return false;
        }

        const isAdActionMaintenanceApiPath = new RegExp(
            /^\/api\/manage-job-ads\/internal\/job-ads\/[^/]+\/(free-prolongation|remind)/,
        ).test(path);
        if (isAdActionMaintenanceApiPath && !abilities.adActionsMaintenance) {
            return false;
        }

        return isAuthenticated;
    };

    const hasAccessToPath = (path: string): boolean => {
        if (shouldBeIgnored(path) || isApiPath(path)) {
            return true;
        }

        const isInternalPage = path.startsWith('/p/internal');
        if (isInternalUser && !isInternalPage) {
            return false;
        }

        if (!isInternalUser && isInternalPage) {
            return false;
        }

        if (path.startsWith('/p/internal/companies') && !abilities.adsList) {
            return false;
        }

        if (path.startsWith('/p/internal/qa') && !abilities.qaPages) {
            return false;
        }

        return isAuthenticated;
    };

    return {
        abilities,
        hasAccessToApiPath,
        hasAccessToPath,
        isAuthenticated,
        isExternalUser,
        isInternalUser,
    };
};
