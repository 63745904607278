/**
 * Protect WIP packages' pages from being accessed.
 */
export default defineNuxtRouteMiddleware((to) => {
    if (process.client) return;

    const { packages, app } = useRuntimeConfig();
    if (app.env !== 'production') return;

    const protectedRoutes = [];
    for (const { isWip, routePrefixes } of Object.values(packages)) {
        if (!isWip) continue;
        protectedRoutes.push(
            ...routePrefixes.page.map(
                (urlPrefix) => new RegExp(`^/${urlPrefix}.*`),
            ),
        );
    }

    if (protectedRoutes.some((route) => route.test(to.fullPath)))
        return abortNavigation();
});
