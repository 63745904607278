<script setup lang="ts">
import { computed } from 'vue';

type AssignedSalesUser = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    isActive: boolean;
    position: string;
    phone: string;
    uuid: string;
    thumbnail: string | null;
};

const props = defineProps({
    assignedSalesUser: {
        default: null,
        type: Object as PropType<AssignedSalesUser | null>,
    },
    open: {
        default: false,
        type: Boolean,
    },
});

const { user: userInfo } = useUser();
const { routes } = userInfo.value;

const emit = defineEmits(['closeModal']);

const show = computed({
    get() {
        return props.open;
    },
    set(value) {
        emit('closeModal', value);
    },
});
</script>

<template>
    <BaseModal
        v-model:visible="show"
        title="Ansprechperson"
        text-center
        center
        @close="$emit('closeModal')"
    >
        <template #footer>
            <div class="mt-6 px-6 text-center md:px-10">
                <template v-if="assignedSalesUser">
                    <BaseText class="mb-7">
                        Kontaktieren Sie jederzeit Ihre Ansprechperson, um Ihre
                        gewünschten Infos zu erhalten.
                    </BaseText>
                    <BaseText>
                        <img
                            v-if="assignedSalesUser?.thumbnail"
                            :src="assignedSalesUser?.thumbnail"
                            class="mx-auto h-[60px] rounded-full"
                            alt="Ansprechperson Bild"
                        />
                        <BaseText variant="bold">
                            {{ assignedSalesUser.firstName }}
                            {{ assignedSalesUser.lastName }}
                        </BaseText>
                        <BaseText>{{ assignedSalesUser.position }}</BaseText>
                        <div>
                            <BaseLink
                                v-if="assignedSalesUser.phone"
                                as="a"
                                :href="'tel:' + assignedSalesUser.phone"
                            >
                                {{ assignedSalesUser.phone }}
                            </BaseLink>
                        </div>
                        <BaseLink
                            v-if="assignedSalesUser.email"
                            as="a"
                            :href="'mailto:' + assignedSalesUser.email"
                        >
                            {{ assignedSalesUser.email }}
                        </BaseLink>
                    </BaseText>
                </template>
                <template v-else>
                    <BaseText>
                        Als Online Shop Kunde haben Sie keine persönliche
                        Ansprechperson. Kontaktieren Sie uns unter
                        <BaseLink as="a" href="mailto:shop-support@karriere.at">
                            shop-support@karriere.at
                        </BaseLink>
                        oder lesen Sie in unserem
                        <BaseLink as="a" :href="routes.faqs" target="_blank">
                            FAQ Bereich
                        </BaseLink>
                        nach um alle relevanten Informationen zu erhalten.

                        <BaseLink as="a" :href="routes.faqs" target="_blank">
                            Zu den FAQ
                        </BaseLink>
                    </BaseText>
                </template>
            </div>
        </template>
    </BaseModal>
</template>
