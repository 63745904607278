export const useOnFirstUserInteraction = (callback: AnyCallback) => {
    const handler = () => {
        callback();
        stopListening();
    };

    const startListening = () => {
        document.body.addEventListener('click', handler);
        document.body.addEventListener('keydown', handler);
        document.body.addEventListener('mousedown', handler);
        document.body.addEventListener('mousemove', handler);
        document.body.addEventListener('scroll', handler, { passive: true });
        document.body.addEventListener('touchstart', handler);
    };

    const stopListening = () => {
        document.body.removeEventListener('click', handler);
        document.body.removeEventListener('keydown', handler);
        document.body.removeEventListener('mousedown', handler);
        document.body.removeEventListener('mousemove', handler);
        document.body.removeEventListener('scroll', handler);
        document.body.removeEventListener('touchstart', handler);
    };

    onMounted(startListening);
    onBeforeUnmount(stopListening);
};
