export default {
  content: () => import("/src/layouts/content.vue").then(m => m.default || m),
  default: () => import("/src/layouts/default.vue").then(m => m.default || m),
  plain: () => import("/src/layouts/plain.vue").then(m => m.default || m),
  "manage-job-ads": () => import("/src/packages/manage-job-ads/layouts/ManageJobAds.vue").then(m => m.default || m),
  "manage-job-ads-form": () => import("/src/packages/manage-job-ads/layouts/ManageJobAdsForm.vue").then(m => m.default || m),
  "manage-job-ads-internal": () => import("/src/packages/manage-job-ads/layouts/ManageJobAdsInternal.vue").then(m => m.default || m),
  "manage-job-ads-qa": () => import("/src/packages/manage-job-ads/layouts/ManageJobAdsQA.vue").then(m => m.default || m),
  "manage-job-ads-styleguide": () => import("/src/packages/manage-job-ads/layouts/ManageJobAdsStyleguide.vue").then(m => m.default || m),
  "manage-talents-default": () => import("/src/packages/manage-talents/layouts/manage-talents-default.vue").then(m => m.default || m),
  "manage-talents-public": () => import("/src/packages/manage-talents/layouts/manage-talents-public.vue").then(m => m.default || m)
}