<script setup lang="ts">
provideHeadlessUseId(() => useId());

const { dataLayer } = useGtm();
const route = useRoute();

// Virtual Page View Tracking d
useScriptEventPage(({ path, title }) => {
    const previousUrl = route.meta.from
        ? decodeURI(
              new URL(route.meta.from.fullPath, window.location.origin).href,
          )
        : undefined;

    // `manage-talents` package currently handles pageview tracking manually
    if (!path.includes('/p/active-sourcing/'))
        dataLayer.push({
            event: 'pageview',
            pageTitle: title,
            pageUrl: path,
            previousUrl,
        });
});

const baseUrl = useRuntimeConfig().public.base.url;
useHead(() => ({
    link: [
        {
            rel: 'canonical',
            href: `${baseUrl}${route.path}`,
        },
    ],
}));
</script>

<template>
    <div>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>
